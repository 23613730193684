* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  background: url('./Assets/images/header_bg.png');
}

a{
  text-decoration: none !important;
}