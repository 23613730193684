.App {
  display: flex;
  flex-direction: column;
}

* {
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "kingthings_trypewriter_2Rg";
  src: url("../Assets/fonts/Trypewriter_2/kingthings_trypewriter_2-webfont.woff2")
      format("woff2"),
    url("../Assets/fonts/Trypewriter_2/kingthings_trypewriter_2-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "kingthings_trypewriter_2Rg";
  src: url("../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff2")
      format("woff2"),
    url("../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "mv_boliregular";
  src: url("../Assets/fonts/muBoli/mvboli_1-webfont.woff2") format("woff2"),
    url("../Assets/fonts/muBoli/mvboli_1-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "intro_rust_gbase_2_line";
  src: url("../Assets/fonts/intro/intro_font-webfont.woff2") format("woff2"),
    url("../Assets/fonts/intro/intro_font-webfont.woff") format("woff");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "square721_cn_btroman";
  src: url("../Assets/fonts/square/sqr721c-webfont.woff2") format("woff2"),
    url("../Assets/fonts/square/sqr721c-webfont.woff") format("woff");

  font-weight: normal;
  font-style: normal;
}

/* Navbar styting */

.logo {
  max-width: 12rem;
  margin: 0 !important;
  display: flex !important;
  justify-content: center !important;
}

.logo img {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.text_white {
  color: #ffffff !important;
}

nav img {
  margin-right: 0.3rem;
}

@media screen and (max-width: 1153px) {
  .logo img {
    width: 115px;
  }

  nav ul {
    font-size: 0.9rem !important;
  }

  .nav-link-1 span,
  .nav-link-1 p {
    font-size: 14px !important;
  }
}

.navbar {
  display: flex;
  justify-content: space-around;
  padding: 1.5rem !important;
  padding-bottom: 0 !important;
  /* padding-top: 1rem !important; */
}

nav ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center !important;
  font-weight: 800;
  font-size: 1.2rem;
  letter-spacing: 2px;
  -webkit-text-stroke: 0.4px;
}

.navbar-nav .phone {
  font-size: 16px;
  text-align: center !important;
}

.home_page_header nav ul a {
  font-family: "kingthings_trypewriter_2Rg";
  color: #4e4a4a;
  text-transform: uppercase;
  font-weight: 800 !important;
}

.home_page_header2 nav ul a {
  width: 100%;
}

.nav_wrapper2 {
  min-height: 80% !important;
}

nav ul p {
  margin-bottom: 0 !important;
  padding-bottom: 10px !important;
  font-size: 18px;
}

.navbar button {
  border: 2px solid black;
}

nav ul .nav-item {
  transition: all 0.2s ease;
}

.nav-link-1 {
  text-decoration: none;
  padding-bottom: 10px !important;
}

.nowrap {
  white-space: nowrap;
}

.nav-link-1 span,
.nav-link-1 p {
  font-size: 17px;
}

.nav-link {
  padding: 10px 30px !important;
  transition: all 0.3s ease;
}

.nav-link:hover {
  background-image: url("../Assets/images/link_bg.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* transform: scale(1.1); */
  color: #ebd967 !important;
}

.navbar-nav {
  filter: none;
}

@media screen and (max-width: 991px) {
  .small_logo {
    max-width: 6rem !important;
    margin: auto;
  }

  .small_logo img {
    width: 100%;
    height: 100%;
  }

  .navbar {
    padding: 1rem;
  }

  nav ul a {
    margin: 0.5rem 0;
  }

  .navbar button {
    float: right !important;
  }
  .logoHideInTab {
    display: none !important;
  }
}

@media screen and (max-width: 576px) {
  .navbar {
    padding: 0.5rem;
  }

  .small_logo {
    max-width: 5rem !important;
    margin: 0;
  }

  .navbar-nav {
    margin-top: 0.5rem;
    align-items: center !important;
  }
}

.navbarNavMainList {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 991px) {
  .navbarNavMainList {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

/* Navbar styling End */

/* Welcome banner Styling */

.welcome_banner {
  margin: 0rem 0 0 0;
}

.burger_div {
  display: flex;
  align-items: center !important;
}

.burger_bg {
  position: relative;
}

.burger_bg::before {
  content: " ";
  background-image: url("../Assets/images/burger_2.png");
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  /* animation-name: zoomInOut; */
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
  filter: none;
}

.ingredient_bg {
  background-image: url("../Assets/images/ingradient_2.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40%;
  height: 50%;
  position: absolute;
  top: -15%;
  right: 15%;

  animation-name: rotate;
  animation-duration: 4s;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

@keyframes zoomInOut {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

.welcome_bg p {
  font-size: 20px;
  color: #f03840;
  font-weight: 600;
  margin: 0;
}

.welcome_to_bg {
  background-image: url("../Assets/images/welcome_bg_pink.svg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 1.5rem;
  width: fit-content;
}

.food_factory_bg {
  background-image: url("../Assets/images/welocome_bg_yellow.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 1rem 3rem;
  width: fit-content;
  position: relative;
  z-index: -8;
}

.food_factory_bgevent {
  padding: 1rem 3rem;
  width: fit-content;
  position: relative;
}

.food_factory_bgevent h1,
.food_factory_bgevent h3 {
  font-family: "kingthings_trypewriter_2Rg" !important;
  font-weight: 700;
  text-align: center !important;
  letter-spacing: 2px;
}

.event_details p {
  margin: 0;
  color: #000;
  font-family: "kingthings_trypewriter_2Rg" !important;
  width: 100%;
  text-align: center;
}

.slick-dots li button:before {
  content: "" !important;
  background-color: #000 !important;
  border-radius: 30px;
  width: 10px !important;
  height: 10px !important;
}

.slick-dots li.slick-active button:before {
  width: 20px !important;
}

.slick-dots li {
  text-align: center;
  width: fit-content;
}

.slick-dots li button {
  text-align: center !important;
}

.stigberets_img {
  width: 55%;
  height: 180%;
  z-index: -1;
  position: absolute;
  bottom: -180px;
  left: -50px;
  opacity: 0.4;
}

.slick-arrow {
  display: none !important;
}

.vega_logo {
  background-image: url("../Assets/images/vega_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: -50px;
  bottom: -270px;
  width: 43%;
  height: 132%;
  opacity: 0.4;
}
/*---Home Page Model 1 ---*/
.custom-modal .modal-dialog {
  margin: 0 auto;
  top: 55%;
}

.HomeeModel .modal-title {
  font-size: 28px;
  font-weight: 400;

  font-family: "Poppins", sans-serif;
}

.HomeeModel .beforrrIcn .modal-body::before {
  content: url("../Assets/images/Cookies1.svg");
  position: absolute;

  left: -0px;
  top: -2rem;
}
.beforrrIcn .modal-body {
  height: 10px;
}
.HomeeModel .modal-body {
  height: 80px;
  font-family: "Poppins", sans-serif;
}
.HomeeModel .openClssBtn {
  display: flex;
  gap: 10px;
}
.HomeeModel .mngeBttn {
  margin-right: 1rem;
}
.HomeeModel .openClssBtn button {
  background-color: transparent;
  color: black;
  border: 1.5px solid black;
  padding: 0px 1.5rem 0px 1.5rem;
  margin-bottom: 10px;
}
.modal-dialog {
  max-width: 60rem;
}
.HomeeModel .modal-body {
  width: 90%;
  padding-left: 10rem;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
.HomeeModel .modal-body span {
  color: #ff1212;
  font-family: "Poppins", sans-serif;
}
.HomeeModel .modal-header {
  padding-left: 10rem;
  display: flex;
  justify-content: space-between !important;
}
.HomeeModel .modal-footer {
  padding-left: 9rem;
  display: flex;
  justify-content: flex-start !important;
  border: none !important;
}
.HomeeModel .modal-footer button {
  font-weight: 400;
  background-color: transparent;
  border: 1.5px solid #666666;
  color: black;
  border: none;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}
.HomeeModel .openBttn:hover,
.HomeeModel .mngeBttn:hover {
  background-color: #f58634;
  color: white;
  border-color: transparent;
}
.HomeeModel .modal-footer button:hover {
  color: #f58634;
}
.HomeeModel .modal-footer button {
  display: flex;
}
.RightArrww {
  padding-left: 4px;
}
.RightArrww img {
  height: 17px;
  width: 16px;
}
.ConTAccpt {
  position: relative;
  display: flex;
}

@media only screen and (max-width: 1000px) {
  .HomeeModel .modal-title {
    font-size: 20px;
  }
  .HomeeModel .modal-content {
    width: 98%;

    margin-left: 5px;
    margin-right: 5px;
    margin-right: 30px;
  }
  .HomeeModel .modal-body {
    width: 100%;
    font-size: 14px;
  }
  .HomeeModel .openClssBtn button {
    font-size: 14px;
    padding: 0px 0.7rem 0px 0.7rem;
  }
}
.accordion-button {
  gap: 0.5rem !important;
}

.accordion-button span {
  font-weight: 500;
  color: #212121 !important;
  white-space: nowrap;
}
@media only screen and (max-width: 580px) {
  .HomeeModel .openClssBtn {
    position: absolute;
    right: 0;
    bottom: 10px;
  }
  .HomeeModel .modal-title {
    font-size: 18px;
  }
  .HomeeModel .modal-footer button,
  .HomeeModel .modal-body {
    font-size: 11px;
  }
  .HomeeModel .openClssBtn button {
    padding: 0px 0.3rem 0px 0.3rem;
    font-size: 13px;
  }
  .HomeeModel .beforrrIcn .modal-body::before {
    top: -2.5rem;
  }
}
@media only screen and (max-width: 486px) {
  .HomeeModel .modal-footer button,
  .HomeeModel .modal-body {
    font-size: 10px;
  }
  .HomeeModel .modal-footer {
    margin-top: 2px;
    padding-left: 0px;
    z-index: 20;
    width: 65%;
  }
}

/*-------------------------------------------------------------------------------------------*/

/*--Model 2--*/
.HomeeModel2 .modal-header {
  display: flex;
  justify-content: space-between !important;
}
.HomeeModel2 .modal-title {
  font-weight: 600;
  font-size: 16px !important;
}
.HomeeModel2 .modal-header p {
  display: flex;
  justify-content: space-between !important;
  font-size: 16px !important;
  color: #ff1212;
  font-weight: 600;
}
.HomeeModel2 .modal-body span {
  color: #ff1212;
  font-size: 16px;
  padding-left: 5px;
}
.fottrBtnModl .modal-footer {
  display: flex;
  justify-content: space-between !important;
}
.leftFottrBtn {
  display: flex;
  gap: 10px;
}
.accpptBtn button,
.RjectBtn button,
.sveSetting {
  color: #000;
  border: 1.5px solid #666666;
  background-color: transparent;
  border-radius: 6px;
  padding: 0px 1rem 0px 1rem;
  font-weight: 500;
}
.accpptBtn button:hover,
.RjectBtn button:hover:hover,
.sveSetting:hover {
  background-color: #f58634;
  color: white;
  border-color: transparent;
}
.cook {
  display: flex;
  flex-direction: column;
  position: relative;
}
.cookbbuttn {
  position: relative;
  border: none;
  background-color: transparent;
}
.drppIcnn {
  position: absolute;
  left: 3.5rem;
  height: 17px;
  bottom: 17px;

  width: 15px;
}
.cookboxx {
  display: flex;
  flex-direction: column !important;
  gap: 10px;
  position: relative;
}
.cookieCont {
  display: flex;
  align-items: center;
  text-align: center;
  height: 2.8rem;
  position: relative;
  background-color: #d9d9d9;
  border-radius: 4px;
}
.cook1,
.cook2,
.cook3,
.cook4 {
  width: 90%;

  display: flex;
  position: relative;
}
.cookieesss {
  display: flex;
  justify-content: space-between;

  padding-top: 2rem;
  font-weight: 600;
}
.cook1 button,
.cook2 button,
.cook3 button,
.cook4 button,
.cook1-content {
  padding-left: 1rem;
}
.cook1-content {
  border-radius: 5px;
}

.rttedImgRight {
  height: 11px;
  width: 13px;
}

.cookieesss p {
  font-size: 15px;
}
.OnOFtoggl {
  height: 100%;
  width: 100%;

  position: relative;
  padding-right: 0.5rem;
}

.tggle {
  position: absolute;
  right: 10px;
  bottom: 13px;
}
.crssImgClss,
.righImgClss {
  position: relative;
}
.crssImgClss img {
  position: absolute;
  top: 30px;

  left: 8px;
  height: 9px;
  width: 10px;
  z-index: 20;
}
.CrsssImgg img {
  position: absolute;
  right: 7px;
  top: 2rem;
  height: 15px;
  width: 18px;
  z-index: 77;
}
.righImgClss img {
  position: absolute;
  right: 7px;
  top: 30px;

  height: 9px;
  width: 10px;
  z-index: 21;
}
.tggle input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.tggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 45px;
  height: 22px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tggle label:after {
  content: "";
  position: absolute;
  cursor: pointer;
  top: 3px;
  left: 5px;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 90px;
  z-index: 999;
  transition: 0.3s;
}

.tggle input:checked + label {
  background: #f58634;
}

.tggle input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.tggle label:active:after {
  width: 13px;
}

.welcome_bg {
  background-size: 90% 90%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.oo_img {
  background-image: url("../Assets/images/00_img.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  position: absolute;
  top: -88px;
  right: -70px;
  width: 40%;
  height: 70%;
  opacity: 0.5;

  /* animation-name: rotate; */
  animation-duration: 4s;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(-0deg);
  }
}

.welcome_bg h1 {
  letter-spacing: 1px;
  font-family: "intro_rust_gbase_2_line";
  text-align: left;
  margin: 0;
  font-size: 50px;
  color: #000;
  white-space: nowrap;
}

.barleys_underline div {
  text-decoration: underline solid black 3px;
}

.pink_bg {
  background-image: url("../Assets/images/welcome_bg_pink.svg");
  background-repeat: no-repeat;
  background-size: contain;
  letter-spacing: 1px;
  color: rgb(70, 86, 65);
  font-size: 45px;
  text-decoration: underline;
  margin: 0;
  position: absolute;
  top: 8px;
  left: 0;
  width: 70%;
  height: 46%;
  z-index: -1;
}

.heart_img {
  margin-left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  padding-bottom: 0.5rem;
}

.welcome_bg a h4 {
  font-size: 20px;
  padding: 30px;
  text-transform: uppercase;
  width: 150px;
  text-align: center;
  background-size: 100% 100%;
  color: #fff;
}

.welcome_bg a {
  text-decoration: none;
  color: #000;
}

.social-icons {
  position: fixed;
  right: 20px;
  bottom: 35%;
  height: 150px;
  width: 30px;
  z-index: 10;
  transition: all 0.3s ease;
}

.social-icons ul {
  margin: 0 !important;
  padding: 0 !important;
}

.social-icons li {
  list-style: none;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.social-icons a img {
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-out;
}

.social-icons a img:hover {
  transform: scale(1.2);
}

@media screen and (max-width: 1200px) {
  .welcome_bg h1 {
    font-size: 40px;
  }

  .barleys_underline span {
    font-size: 40px;
  }

  .burger_div {
    height: 350px;
  }

  .good_guy {
    background-image: url(http://localhost:3000/static/media/good_guys.80aabe1….png);
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.3;
    position: absolute;
    left: -50px !important;
    top: -174px !important;
    width: 22% !important;
    height: 19% !important;
    transform: rotate(-10deg);
  }

  .billdale {
    background-image: url(http://localhost:3000/static/media/billdale_logo.cb7a945….png);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 22% !important;
    right: 12% !important;
    width: 24% !important;
    height: 19% !important;
    opacity: 0.5;
  }

  .spike {
    background-image: url(http://localhost:3000/static/media/spike_logo.a54639f….png);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -130px !important;
    right: 0 !important;
    width: 40% !important;
    height: 25% !important;
    opacity: 0.3;
  }

  .content_text_1,
  .content_text_2,
  .content_text_3 {
    padding: 0;
  }

  .oo_img {
    background-image: url("../Assets/images/00_img.png");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
    position: absolute;
    top: -88px;
    right: -25px;
    width: 40%;
    height: 70%;
    opacity: 0.5;
  }

  .logoImg_wrapper img {
    width: 120px !important;
    height: 120px !important;
  }
}

@media screen and (max-width: 1100px) {
  .logoImg_wrapper img {
    width: 100px !important;
    height: 100px !important;
  }
}

@media screen and (max-width: 991px) {
  .burger_div {
    height: 300px;
  }

  .welcome_bg h1 {
    font-size: 27px;
  }

  .barleys_underline span {
    font-size: 27px;
  }

  .oo_img {
    position: absolute;
    top: -70px;
    right: -50px;
    width: 23%;
    height: 80%;
  }
}

@media screen and (max-width: 767px) {
  .burger_div {
    height: 300px;
    margin-top: 4rem;
  }

  .welcome_banner .row {
    display: flex;
    flex-direction: column-reverse;
  }

  .welcome_bg {
    margin: auto;
    margin-bottom: 5rem !important;
    margin-top: 3rem !important;
  }

  .oo_img {
    position: absolute;
    top: 100px;
    right: -30px;
    width: 15%;
    height: 70%;
  }

  .welcome_bg h1 {
    font-size: 40px;
  }

  .barleys_underline span {
    font-size: 40px;
  }

  .stigberets_img {
    width: 30% !important;
    height: 110% !important;
    z-index: -1 !important;
    position: absolute !important;
    bottom: -135px !important;
    left: 0px !important;
    opacity: 0.4;
  }

  .vega_logo {
    position: absolute;
    right: -25px !important;
    bottom: -190px !important;
    width: 25% !important;
    height: 100% !important;
    opacity: 0.4;
  }

  .good_guy {
    position: absolute;
    left: 30px !important;
    top: -100% !important;
    width: 16% !important;
    height: 26% !important;
  }

  .spike {
    top: -38% !important;
    right: 0% !important;
    width: 24% !important;
    height: 31% !important;
  }

  .nav-link {
    padding: 6px 0px !important;
  }
}

@media screen and (max-width: 576px) {
  .navbar {
    justify-content: space-between;
  }

  .welcome_banner {
    margin-top: 3rem;
  }

  .welcome_bg {
    margin: 0 !important;
  }

  .social-icons {
    position: fixed;
    right: 20px;
    bottom: 30%;
    width: 38px;
  }

  .stigbergets_logo {
    position: absolute;
    bottom: -140px !important;
    right: 25px !important;
    width: 24% !important;
    height: 44% !important;
  }

  .oo_img {
    position: absolute;
    top: 115px;
    right: 15px;
  }

  .stigberets_img {
    width: 30% !important;
    height: 110% !important;
    z-index: -1 !important;
    position: absolute !important;
    bottom: -95px !important;
    left: 15px !important;
    opacity: 0.4;
  }

  .vega_logo {
    position: absolute;
    left: 15px !important;
    bottom: -50px !important;
    width: 25% !important;
    height: 24% !important;
    opacity: 0.4;
  }
}

@media screen and (max-width: 480px) {
  .welcome_bg h1 {
    font-size: 30px;
  }

  .barleys_underline span {
    font-size: 35px;
  }

  .food_factory_bg {
    padding: 1rem 1.5rem;
  }
}

/* Welcome banner Styling End */

/* Content section styling */

.content_text {
  color: #4e4a4a;
  font-family: "kingthings_trypewriter_2Rg";
  font-size: 26px;
  letter-spacing: 2px;
  line-height: 2;
}

@media screen and (max-width: 991px) {
  .content_text {
    font-family: "kingthings_trypewriter_2Rg";
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 2;
  }
}

@media screen and (max-width: 576px) {
  .content_text {
    font-family: "kingthings_trypewriter_2Rg";
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 2;
  }
}

/* Welcome banner styling end */

/* Content section styling */

section.content_section {
  margin: 2rem 1rem;
  margin-top: 1rem !important;
}

.content_text_1 {
  color: #4e4a4a;
  margin-left: 2rem;
  margin-top: 5rem;
  padding: 1rem;
  font-family: "kingthings_trypewriter_2Rg";
  font-size: 22px;
  letter-spacing: 2px;
  line-height: 2;
  -webkit-text-stroke: 0.5px;
}

.akia_logo {
  background-image: url("../Assets/images/akia.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 25%;
  top: -70px;
  width: 15%;
  height: 86%;
  opacity: 0.6;
  z-index: -1;
}

.poppels_2_logo {
  background-image: url("../Assets/images/poppels_2.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  position: absolute;
  bottom: -100px;
  right: 23%;
  width: 14%;
  height: 66%;
  opacity: 0.3;
}

.b_logo {
  background-image: url("../Assets/images/b_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 20%;
  right: 28px;
  width: 16%;
  height: 105%;
  opacity: 0.4;
}

.gotlands_logo {
  background-image: url("../Assets/images/gotlands_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -85%;
  right: 4%;
  width: 18%;
  height: 85%;
  opacity: 1;
}

.stockholm_logo {
  background-image: url("../Assets/images/stockholmbrewingco_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 37%;
  bottom: -130px;
  width: 22%;
  height: 43%;
  opacity: 0.4;
  transform: rotate(-10deg);
}

.stigbergets_logo {
  background-image: url("../Assets/images/stigberget_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -260px;
  right: 50px;
  width: 21%;
  height: 92%;
  opacity: 0.8;
}

.fermen_logo {
  background-image: url("../Assets/images/fermen-removebg-preview.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  position: absolute;
  left: 6%;
  bottom: -220px;
  width: 18%;
  height: 90%;
  opacity: 0.4;
  /* animation-name: rotate; */
  animation-duration: 4s;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

.omni {
  background-image: url("../Assets/images/omnipollo_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -60px;
  left: 0;
  width: 35%;
  height: 42%;
  opacity: 0.4;
}

@media screen and (max-width: 1200px) {
  .akia_logo {
    position: absolute;
    right: 25%;
    top: -30px;
    width: 15%;
    height: 50%;
    opacity: 0.6;
  }

  .poppels_2_logo {
    position: absolute;
    bottom: 24px;
    right: 23%;
    width: 14%;
    height: 36%;
    opacity: 0.3;
  }

  .b_logo {
    position: absolute;
    top: 24%;
    right: 28px;
    width: 16%;
    height: 42%;
    opacity: 0.4;
  }

  .gotlands_logo {
    position: absolute;
    bottom: -20%;
    right: 4%;
    width: 18%;
    height: 47%;
    opacity: 1;
  }

  .stockholm_logo {
    position: absolute;
    left: 37%;
    bottom: -130px;
    width: 22%;
    height: 25%;
    opacity: 0.4;
  }

  .fermen_logo {
    position: absolute;
    position: absolute;
    left: 18%;
    bottom: -160px;
    width: 18%;
    height: 50%;
    opacity: 0.4;
    /* animation-name: rotate; */
    animation-duration: 4s;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
}

@media screen and (max-width: 991px) {
  .stigberets_img {
    width: 60%;
    height: 200%;
    z-index: -1;
    position: absolute;
    bottom: -170px;
    left: -50px;
    opacity: 0.4;
  }

  .vega_logo {
    position: absolute;
    right: 0px;
    bottom: -200px;
    width: 43%;
    height: 132%;
    opacity: 0.4;
  }

  .akia_logo {
    position: absolute;
    right: 24%;
    top: -30px;
    width: 15%;
    height: 41%;
    opacity: 1;
  }

  .poppels_2_logo {
    position: absolute;
    bottom: 116px;
    right: 23%;
    width: 14%;
    height: 29%;
    opacity: 0.3;
  }

  .fermen_logo {
    position: absolute;
    position: absolute;
    left: 10%;
    bottom: -160px;
    width: 18%;
    height: 39%;
    opacity: 0.4;
    /* animation-name: rotate; */
    animation-duration: 4s;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }

  .gotlands_logo {
    position: absolute;
    bottom: -26%;
    right: 4%;
    width: 28%;
    height: 51%;
    opacity: 1;
  }
}

@media screen and (max-width: 1075px) {
  .content_text_1 {
    margin-left: 1.5rem;
    margin-top: 4rem;
    padding: 0.8rem;
    font-family: "kingthings_trypewriter_2Rg";
    font-size: 20px;
    letter-spacing: 2px;
    -webkit-text-stroke: 0.5px;
    line-height: 2;
  }
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 832px) {
  .content_text_1 {
    margin-left: 1rem;
    margin-top: 3rem;
    padding: 0.8rem;
    font-family: "kingthings_trypewriter_2Rg";
    font-size: 18px;
    letter-spacing: 1.5px;
    -webkit-text-stroke: 0.5px;
    line-height: 2;
  }
}

@media screen and (max-width: 768px) {
  .content_text_1 {
    margin-left: 0rem;
  }

  .akia_logo {
    position: absolute;
    right: 5%;
    top: -100px;
    width: 17%;
    height: 27%;
    opacity: 1;
  }

  .b_logo {
    position: absolute;
    top: 16%;
    right: 20px;
    width: 17%;
    height: 26%;
    opacity: 0.4;
  }

  .poppels_2_logo {
    position: absolute;
    bottom: 70px;
    right: 5%;
    width: 20%;
    height: 29%;
    opacity: 0.3;
  }

  .gotlands_logo {
    position: absolute;
    bottom: -35%;
    right: 4%;
    width: 30%;
    height: 43%;
    opacity: 1;
  }

  .stockholm_logo {
    position: absolute;
    left: 33%;
    bottom: -100px;
    width: 30%;
    height: 19%;
    opacity: 0.4;
  }

  .fermen_logo {
    position: absolute;
    position: absolute;
    left: 3%;
    bottom: -170px;
    width: 28%;
    height: 42%;
    opacity: 0.4;
    /* animation-name: rotate; */
    animation-duration: 4s;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
}

@media screen and (max-width: 576px) {
  .akia_logo {
    position: absolute;
    right: 10%;
    top: -100px;
    width: 20%;
    height: 40%;
    opacity: 1;
  }

  .b_logo {
    position: absolute;
    top: 0%;
    right: -10px;
    width: 22%;
    height: 20%;
    opacity: 0.4;
  }

  .fermen_logo {
    position: absolute;
    position: absolute;
    left: 0%;
    bottom: -100px;
    width: 26%;
    height: 45%;
    opacity: 0.4;
    /* animation-name: rotate; */
    animation-duration: 4s;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }

  .stockholm_logo {
    position: absolute;
    left: 31%;
    bottom: -93px;
    width: 33%;
    height: 19%;
    opacity: 0.4;
  }

  .gotlands_logo {
    position: absolute;
    bottom: -56%;
    right: 3%;
    width: 33%;
    height: 40%;
    opacity: 1;
  }

  .poppels_2_logo {
    position: absolute;
    bottom: -20%;
    right: 1%;
    width: 21%;
    height: 33%;
    opacity: 0.3;
  }
}

/* Content_1 section styling end*/

/* Content_2 section styling */

.content_div_2 {
  margin-top: 12rem;
}

.content_div_2_container {
  height: 800px;
}

.champagne_bg {
  position: relative;
}

.beer_img {
  vertical-align: baseline;
}

.content_text_2 {
  color: #4e4a4a;
  margin-top: 2rem;
  padding: 1rem;
  font-family: "kingthings_trypewriter_2Rg";
  font-size: 22px;
  letter-spacing: 2px;
  line-height: 1.8;
  -webkit-text-stroke: 0.5px;
}

.peanut_3 {
  position: absolute;
  top: -30px;
  position: absolute;
  right: 70px;
  width: 24%;
  height: 28%;
}

.smallPeanut2 {
  position: absolute;
  right: 163px;
  bottom: -48px;
  width: 17%;
  height: 23%;

  animation-name: rotate;
  animation-duration: 4s;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

.logos_div {
  height: inherit;
}

.beerbliotek {
  background-image: url("../Assets/images/beerbliotek_img.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 11%;
  left: 4%;
  width: 27%;
  height: 26%;
  opacity: 0.4;
  transform: rotate(10deg);
}

.joker {
  background-image: url("../Assets/images/joker_img.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 4%;
  left: 40%;
  width: 20%;
  height: 33%;
  opacity: 0.4;
}

.brutal {
  background-image: url("../Assets/images/brutal_logo_new.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -20px;
  right: 14%;
  width: 16%;
  height: 26%;
  opacity: 0.4;
}

.billdale {
  background-image: url("../Assets/images/billdale_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 24%;
  right: 12%;
  width: 24%;
  height: 25%;
  opacity: 0.5;
}

.good_guy {
  background-image: url("../Assets/images/good_guys.png");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.3;
  position: absolute;
  left: 35px;
  top: -174px;
  width: 22%;
  height: 33%;
  transform: rotate(-10deg);
}

.spike {
  background-image: url("../Assets/images/spike_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -170px;
  right: 0;
  width: 40%;
  height: 45%;
  opacity: 0.3;
}

@media screen and (max-width: 991px) {
  .beer_div,
  .nonveg_div {
    display: flex;
    flex-direction: column;
  }

  .content_div_2_container {
    height: fit-content;
  }

  .logos_div {
    height: 335px;
  }

  .beerbliotek {
    background-image: url("../Assets/images/beerbliotek_img.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 11%;
    left: 5%;
    width: 27%;
    height: 31%;
    opacity: 0.4;
    transform: rotate(10deg);
  }

  .joker {
    top: 4%;
    left: 38%;
    width: 20%;
    height: 43%;
  }

  .brutal {
    top: -20px;
    right: 14%;
    width: 16%;
    height: 34%;
  }

  .billdale {
    top: 32% !important;
    right: 8% !important;
    width: 24% !important;
    height: 33% !important;
  }

  .good_guy {
    position: absolute;
    left: 0px !important;
    top: -100% !important;
    width: 12% !important;
    height: 23% !important;
  }

  .spike {
    top: 70%;
    left: 0%;
    width: 17%;
    height: 31%;
  }

  .smallPeanut2 {
    position: absolute;
    right: 125px;
    bottom: -40px;
    width: 17%;
    height: 23%;
  }
}

@media screen and (max-width: 768px) {
  .champagne_bg {
    height: inherit;
  }

  .smallPeanut2 {
    position: absolute;
    right: 120px;
    bottom: -23px;
    width: 15%;
    height: 21%;
  }
}

@media screen and (max-width: 576px) {
  .content_text_1,
  .content_text_2,
  .content_text_3 {
    padding: 0;
    font-size: 16px !important;
    letter-spacing: 1px !important;
    line-height: 1.8;
  }

  .brutal {
    top: 0px;
    right: 4%;
    width: 20%;
    height: 23%;
  }

  .beerbliotek {
    background-image: url("../Assets/images/beerbliotek_img.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 11%;
    left: 0%;
    width: 36%;
    height: 23%;
    opacity: 0.4;
    transform: rotate(10deg);
  }

  .joker {
    top: 8%;
    left: 38%;
    width: 35%;
    height: 28%;
  }

  .good_guy {
    position: absolute;
    left: 10px !important;
    top: -173% !important;
    width: 20% !important;
    height: 37% !important;
  }

  .billdale {
    top: 30% !important;
    right: 16% !important;
    width: 36% !important;
    height: 27% !important;
  }

  .spike {
    top: -170% !important;
    left: 80%;
    width: 18% !important;
    height: 26% !important;
  }

  .peanut_3 {
    position: absolute;
    top: -20px;
    position: absolute;
    right: 40px;
    width: 24%;
    height: 28%;
  }
}

/* Content_2 section styling end */

/* Content_3 section styling */

.nonveg_bg .nonveg_img {
  width: 100%;
  height: 100%;
}

.nonveg_bg .red_chilli {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 46%;
  height: 41%;
  z-index: -1;
}

.chilli_powder {
  position: absolute;
  left: 0;
  top: 65px;
  width: 53%;
  height: 43%;
}

.tomato {
  position: absolute;
  right: -80px;
  top: 60px;
  width: 20%;
  height: 16%;
}

.coriender {
  position: absolute;
  left: 20%;
  top: 0;
  width: 32%;
  height: 22%;

  animation-name: rotate;
  animation-duration: 4s;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

.content_text_3 {
  color: #4e4a4a;
  margin: auto;
  padding: 1rem;
  font-family: "kingthings_trypewriter_2Rg";
  font-size: 22px;
  letter-spacing: 2px;
  line-height: 1.8;
  -webkit-text-stroke: 0.5px;
}

@media screen and (max-width: 1200px) {
  .tomato {
    position: absolute;
    right: -58px !important;
    top: 60px !important;
    width: 20% !important;
    height: 16% !important;
  }

  .nonveg_bg .red_chilli {
    position: absolute;
    right: -70px !important;
    bottom: 0;
    width: 46%;
    height: 41%;
    z-index: -1;
  }
}

@media screen and (max-width: 991px) {
  .content_div_3 {
    margin-top: -7rem;
  }

  .content_text_3 p {
    margin-top: 1rem;
  }

  .pp {
    position: absolute;
    left: 5% !important;
    bottom: -85px !important;
    width: 33% !important;
    height: 27% !important;
  }

  .old_island {
    position: absolute;
    bottom: -37% !important;
    right: 8% !important;
    width: 27% !important;
    height: 39% !important;
  }
}

@media screen and (max-width: 768px) {
}

/* Content_3 section styling end */

/* Content_4 section styling */

.content_div_4 {
  margin: 4rem 0;
}

.content_text_4 {
  color: #4e4a4a;
  margin: auto;
  padding: 1rem;
  font-family: "kingthings_trypewriter_2Rg";
  font-size: 45px;
  letter-spacing: 2px;
  line-height: 1.8;
  text-align: center;
  -webkit-text-stroke: 0.5px;
}

.fries_img {
  width: 55%;
  animation-name: zoomInOut;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
  filter: none;
}

.pp {
  background-image: url("../Assets/images/pp_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 5%;
  bottom: -110px;
  width: 50%;
  height: 33%;
  opacity: 0.8;
}

.old_island {
  background-image: url("../Assets/images/old_island_img.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -58%;
  right: 0%;
  width: 37%;
  height: 39%;
  opacity: 0.4;
}

.dugges {
  background-image: url("../Assets/images/dugges.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 32%;
  width: 14%;
  height: 40%;
}

.ten_hands {
  background-image: url("../Assets/images/ten_hands_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0%;
  left: 9%;
  width: 25%;
  height: 41%;
  opacity: 0.3;
  transform: rotate(25deg);
}

.goteburgs {
  background-image: url("../Assets/images/goteburgs.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 23%;
  right: 43%;
  width: 19%;
  height: 43%;
  opacity: 0.3;
}

.fry_stick_1 {
  background-image: url("../Assets/images/fry_stick_1.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 30%;
  bottom: 71px;
  width: 10%;
  height: 21%;
  opacity: 1;
}

.fry_stick_2 {
  background-image: url("../Assets/images/fry_stick_2.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 33%;
  bottom: 31px;
  width: 18%;
  height: 13%;
  opacity: 1;
}

.fry_stick_3 {
  background-image: url("../Assets/images/fry_stick_3.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 11%;
  bottom: 200px;
  width: 18%;
  height: 21%;
  opacity: 1;
}

.fry_stick_4 {
  background-image: url("../Assets/images/fry_stick_4.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 13%;
  bottom: 60px;
  width: 12%;
  height: 34%;
  opacity: 1;
}

@media screen and (max-width: 1200px) {
  .pp {
    background-image: url("../Assets/images/pp_logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 5%;
    bottom: -110px;
    width: 50%;
    height: 24%;
    opacity: 0.8;
  }

  .content_div_4 {
    margin-top: 8rem;
  }

  .content_text_4 {
    color: #4e4a4a;
    margin: auto;
    padding: 1rem;
    font-family: "kingthings_trypewriter_2Rg";
    font-size: 35px;
    letter-spacing: 2px;
    -webkit-text-stroke: 0.5px;
    white-space: nowrap;
  }

  .dugges {
    background-image: url("../Assets/images/dugges.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -40px;
    left: 32%;
    width: 14%;
    height: 40%;
  }

  .ten_hands {
    background-image: url("../Assets/images/ten_hands_logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 0%;
    left: 5%;
    width: 25%;
    height: 35%;
    opacity: 0.4;
  }

  .fry_stick_3 {
    background-image: url("../Assets/images/fry_stick_3.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    right: 11%;
    bottom: 170px;
    width: 18%;
    height: 21%;
    opacity: 1;
  }
}

@media screen and (max-width: 991px) {
  .dugges {
    position: absolute;
    top: -35px;
    left: 37%;
    width: 14%;
    height: 40%;
  }

  .goteburgs {
    position: absolute;
    bottom: 7%;
    right: 47%;
    width: 19%;
    height: 39%;
  }

  .fry_stick_1 {
    position: absolute;
    left: 28%;
    bottom: 25px;
    width: 10%;
    height: 26%;
  }

  .fry_stick_2 {
    position: absolute;
    left: 31%;
    bottom: 6px;
    width: 22%;
    height: 15%;
  }

  .fry_stick_3 {
    position: absolute;
    right: 15%;
    bottom: 110px;
    width: 15%;
    height: 25%;
  }

  .fry_stick_4 {
    position: absolute;
    right: 13%;
    bottom: 25px;
    width: 14%;
    height: 41%;
  }
}

@media screen and (max-width: 768px) {
  .content_text_4 {
    margin-top: 3rem !important;
  }

  .dugges {
    position: absolute;
    top: -35px;
    left: 40%;
    width: 16%;
    height: 18%;
  }

  .ten_hands {
    position: absolute;
    bottom: 18%;
    left: 2%;
    width: 28%;
    height: 15%;
  }

  .goteburgs {
    position: absolute;
    top: 30%;
    right: 5%;
    width: 26%;
    height: 22%;
    opacity: 0.4;
  }
}

@media screen and (max-width: 576px) {
  .content_text_4 {
    color: #4e4a4a;
    margin: auto;
    padding: 1rem;
    font-family: "kingthings_trypewriter_2Rg";
    font-size: 25px;
    letter-spacing: 2px;
    line-height: 1.8;
    -webkit-text-stroke: 0.5px;
  }

  .ten_hands {
    position: absolute;
    bottom: 0%;
    left: 10%;
    width: 25%;
    height: 14%;
    opacity: 0.4;
  }

  .tomato {
    position: absolute;
    right: -40px !important;
    top: 40px !important;
    width: 20% !important;
    height: 16% !important;
  }

  .nonveg_bg .red_chilli {
    position: absolute;
    right: -50px !important;
    bottom: 0;
    width: 40%;
    height: 41%;
    z-index: -1;
  }

  .pp {
    position: absolute;
    left: 10% !important;
    bottom: -75px !important;
    width: 45% !important;
    height: 28% !important;
  }

  .old_island {
    position: absolute;
    bottom: -25% !important;
    right: 0% !important;
    width: 33% !important;
    height: 24% !important;
  }

  .dugges {
    position: absolute;
    top: -46px;
    left: 66%;
    width: 22%;
    height: 43%;
  }

  .goteburgs {
    position: absolute;
    top: -10%;
    left: 0%;
    width: 29%;
    height: 36%;
    opacity: 0.4;
  }

  .ten_hands {
    position: absolute;
    bottom: 22%;
    left: 0%;
    width: 29%;
    height: 25%;
    opacity: 0.4;
  }

  .fry_stick_3 {
    position: absolute;
    right: 15%;
    bottom: 65px;
    width: 16%;
    height: 27%;
  }

  .fry_stick_4 {
    position: absolute;
    right: 12%;
    bottom: 10px;
    width: 15%;
    height: 43%;
  }

  .fry_stick_1 {
    position: absolute;
    left: 28%;
    bottom: 0px;
    width: 14%;
    height: 31%;
  }

  .fry_stick_2 {
    position: absolute;
    left: 31%;
    bottom: -11px;
    width: 24%;
    height: 17%;
  }
}

@media screen and (max-width: 380px) {
  .fermen_logo {
    position: absolute;
    position: absolute;
    left: 0%;
    bottom: -130px;
    width: 30%;
    height: 35%;
    opacity: 0.4;
    /* animation-name: rotate; */
    animation-duration: 4s;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }

  .good_guy1 {
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.3;
    position: absolute;
    left: -12px !important;
    top: -80px !important;
    width: 35% !important;
    height: 35% !important;
    transform: rotate(-10deg);
  }

  .bottom_btn {
    font-family: "kingthings_trypewriter_2Rg";
    width: 70% !important;
    height: 40px !important;
    font-size: 16px !important;
  }
}

.row {
  margin: 0 !important;
}

.good_guy1 {
  background-image: url("../Assets/images/good_guys.png");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.3;
  position: absolute;
  left: 9%;
  top: -22%;
  width: 22%;
  height: 23%;
  transform: rotate(-10deg);
}

.spike1 {
  background-image: url("../Assets/images/spike_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -45px !important;
  right: -100px !important;
  width: 40% !important;
  height: 25% !important;
  opacity: 0.3;
}

.bottom_nav_btn {
  position: relative;
}

.bottom_btn {
  font-family: "kingthings_trypewriter_2Rg";
  width: 80%;
  height: 45px;
  font-size: 20px;
  letter-spacing: 1px;
  padding: 0.5rem;
  background-color: #f25f5f;
  border-radius: 10px;
  border: 1px solid #bebebe;
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  z-index: 999;
  transition: all 0.5s ease;
}

.bottom_btn a {
  color: #000 !important;
}

/* --------------------------------------------------------------------------------------- */

.beer_logo_container {
  display: flex;
  margin: 0;

  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.slider-row {
  display: flex;
  width: 300%;
}

.image_group {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.beer_logo_heading {
  text-align: center;
  font-family: "kingthings_trypewriter_2Rg";
  font-weight: 600;
}

.logoImg_wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999999999999;
  justify-content: flex-start;
}

.logoImg_wrapper img {
  width: 150px;
  height: 150px;
  position: relative;
  left: -2000px;
  animation: rotateTranslate 8s infinite linear;
  animation-delay: 0s !important;
  transition: ease-in-out;
  border-radius: 50%;
}

.hideandshow {
  position: relative;
  left: -2000px;
  animation: translateFade 8s infinite linear;
  animation-delay: 0s !important;
  transition: ease-in-out;
  font-weight: 500;
}

@keyframes translateFade {
  0% {
    left: -2000px;
    opacity: 0;
  }
  25% {
    left: 0px;
    opacity: 0.4;
  }
  50% {
    left: 0px;
    opacity: 1;
  }
  75% {
    left: 0px;
    opacity: 0.4;
  }
  100% {
    left: 2000px;
    opacity: 0;
  }
}

@media screen and (max-width: 991px) {
  .beer_logo_caps {
    margin-top: 3rem;
  }

  .logoImg_wrapper img {
    width: 80px;
    height: 80px;
  }

  .hideandshow {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 850px) {
  .logoImg_wrapper img {
    width: 75%;
    position: relative;
    left: -2000px;
    animation: rotateTranslate 8s infinite linear;
    animation-delay: 0s !important;
    transition: ease-in-out;
  }

  .beer_logo_caps {
    margin-top: 3rem;
  }

  .logoImg_wrapper img {
    width: 70px !important;
    height: 70px !important;
  }
}

@media screen and (max-width: 700px) {
  .logoImg_wrapper img {
    width: 85%;
    position: relative;
    left: -1000px;
    animation: rotateTranslate 10s infinite linear;
    animation-delay: 0s !important;
    transition: ease-in-out;
  }

  .beer_logo_caps {
    margin-top: 5rem !important;
  }
}

@media screen and (max-width: 576px) {
  .logoImg_wrapper img {
    width: 100%;
    position: relative;
    left: -1000px;
    animation: rotateTranslate 10s infinite linear;
    animation-delay: 0s !important;
    transition: ease-in-out;
  }

  .beer_logo_caps {
    margin-top: 2rem !important;
  }

  .logoImg_wrapper img {
    width: 50px !important;
    height: 50px !important;
  }

  .hideandshow {
    font-size: 13px !important;
  }
}

@keyframes rotateTranslate {
  0% {
    transform: rotate(0deg);
    left: -2000px;
  }
  25% {
    transform: rotate(360deg);
    left: 0px;
  }
  50% {
    left: 0px;
  }
  75% {
    transform: rotate(360deg);
    left: 0px;
  }
  100% {
    transform: rotate(820deg);
    left: 2000px;
  }
}

@media screen and (max-width: 991px) {
  .food_factory_bgevent {
    padding: 0.5rem 0 !important;
  }

  .welcome_banner {
    margin-top: 1rem !important;
  }

  .food_factory_bgevent h1 {
    font-size: 22px !important;
  }
  .food_factory_bgevent h3 {
    font-size: 20px !important;
  }

  .event_details p {
    font-size: 17px !important;
  }
}

/* ---------------------styles for safai-------------------- */

/* @media screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
  .burger_div {
    height: 210px;
    margin-top: 4rem;
  }

  .navbar {
    padding: 1rem !important;
    padding-bottom: 0 !important;
  }
} */

.burger_div_safari {
  height: 210px !important;
  margin-top: 4rem !important;
}

.navbar_safari {
  padding: 1rem !important;
  padding-bottom: 0 !important;
}

/* ------------------------------------------------------------------------------------ */

.barleysBLogcardbody {
  padding: 8px;
}

.barleysBLogcard {
  transition: all 0.3s ease-in;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px;
  color: #4e4a4a;
}

.barleysBLogcard:hover {
  transform: scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: rgb(255, 195, 65, 0.2);
}

.barleysBLogcardHead img {
  width: 100%;
  border-radius: 7px;
}

.barleysBLogcardbody h5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-weight: 700;
  letter-spacing: 1px;
}

.barleysBLogcardbody h6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  line-height: 1.5rem;
  font-weight: 600 !important;
  letter-spacing: 1px;
}

.forMoreBtn {
  text-align: right;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.forMoreBtn button {
  border: none;
  background-color: transparent;
  border-bottom: 2px solid #4e4a4a;
  font-family: "kingthings_trypewriter_2Rg";
  color: #4e4a4a !important;
  text-transform: uppercase;
  font-weight: 800 !important;
  transition: all 0.3s ease;
  letter-spacing: 1px;
}

.forMoreBtn button:hover {
  border: 2px solid #4e4a4a;
  border-radius: 7px;
  padding: 0.2rem 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: rgb(255, 195, 65, 0.3);
}

a {
  text-decoration: none !important;
  color: #4e4a4a;
  font-family: "kingthings_trypewriter_2Rg";
}

.blogPostcontainer {
  font-family: "kingthings_trypewriter_2Rg";
  margin-top: 4rem;
}

.blogPostHeading {
  text-align: center;
  margin-bottom: 2rem;
}

.blogheadImg {
  padding: 0;
}

.blogheadImg img {
  width: 100%;
  border-radius: 10px;
}

.blogPostcontainer h4 {
  font-weight: 800;
  letter-spacing: 1px;
  margin-bottom: 1.5rem;
}

.blogPostcontainer h5 {
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}

.blogPostcontainer p {
  color: #4e4a4a;
  -webkit-text-stroke: 0.5px !important;
  letter-spacing: 1px;
  line-height: 1.7rem;
  font-size: 20px !important;
}

.blogPostcontainer ul {
  padding-left: 1.5rem;
  list-style-type: decimal;
}

.blogPostcontainer p span {
  color: #000;
  letter-spacing: 1px;
}

.blogPosthEADcONTAINER {
  margin-bottom: 1.5rem !important;
}

.blogsCOntainer {
  margin: 4rem auto;
  font-family: "kingthings_trypewriter_2Rg";
}

.blogsCOntainer .blogPostHeading {
  -webkit-text-stroke: 1px !important;
  letter-spacing: 1px;
  color: #4e4a4a;
  margin: auto;
  margin-bottom: 4rem;
  width: 90%;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .blogPostcontainer p {
    color: #4e4a4a;
    -webkit-text-stroke: 0.5px !important;
    letter-spacing: 1px;
    line-height: 1.7rem;
    font-size: 16px !important;
  }
}
