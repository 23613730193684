/* Overlay (darkened background) */
@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('../Assets/BookaTableAsset/Fonts/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.modal-overlay111 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Transparent dark overlay */
  z-index: 1000;
  /* Ensure overlay appears above other content */
  /* display: none; */
  /* Hidden by default */
}

/* Modal content (right-side panel) */
.modal-content111 {
  position: fixed;
  top: 5px;
  right: -340px;
  /* Start off-screen */
  width: 350px;
  height: 98%;
  background-image: url('../Assets/images/ModelBackground.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  z-index: 1001;
  /* Modal should appear above the overlay */
  border-radius: 10px;
  /* overflow-y: scroll; */




  ::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color:#F6832D!important;
    border-radius: 50px !important;
   
  }
}

.modal-content111::-webkit-scrollbar {
  width: 5px;
  
}

.modal-content111::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Color of the scrollbar track */
  border-radius: 10px; /* Radius of the scrollbar thumb */

}

.modal-content111::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Radius of the scrollbar thumb */
}

.modal-content111::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovered */
}

@media (max-width: 586px) {
  .modal-content111 {
   
    width: 350px;
    height: 98%;
  }
  }

.modal-content111.show {
  transform: translateX(-100%);
  /* Move from right to the screen */
}

.close-button {
  position: absolute;
  top: -15px;
  left: -75px;
  cursor: pointer;
  width: 36px;
  margin: 36px;
}

/* Form styling */
.booking-form .form-item {
  margin-bottom: 15px;
}

.booking-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.booking-form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.deal-section {
  margin-top: 20px;
  text-align: center;
}

.event-image {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.deal-box {
  padding: 10px;
  background-color: #ffe5e5;
  border-radius: 4px;
  font-weight: bold;
}

.bookTableTop {
  width: 100%;
  height: 60px;
  background-color: #585858;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    /* text-align: center; */
    color: #fff;
    font-size: 24.75px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-family: 'Gilroy-ExtraBold', sans-serif;
  }
}
@media (max-width: 586px) {
  .bookTableTop {
    height: 40px; /* Reduce height for smaller screens */
    border-radius: 3px; /* Adjust border-radius for smaller screens */
  }

  .bookTableTop h2 {
    font-size: 18px; /* Adjust font size for smaller screens */
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}

.PhoneNumber {
  text-align: center;
  color: #ffffff;
  font-family: 'Gilroy-ExtraBold', sans-serif;
  font-size: 24px;
  font-weight: 400;
  /* line-height: 49.5px; */
  letter-spacing: 2.25px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
@media (max-width: 586px) {
  .PhoneNumber {
    font-size: 20px;
    letter-spacing: 1.25px;
    margin-top: 5px;
  }
  
}

.dateget {
  width: 100%;
  height: 40px;
  gap: 10px;
  border-radius: 4px 0px 0px 0px;
  opacity: 1;
  background-color: #F2F2F2;
  cursor: pointer;
  margin-top: 1rem;
}


.calenderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-items: center;
  background-color: #fff;
  margin: 1rem 2rem;
}

.img-div-calender {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
  /* justify-content: space-between; */
}

.setup {
  display: flex
}

.square {
  height: 100%;
  width: 85px;
  background-color: #F6832D;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* Center align content vertically */
}

.square img {
  height: 24px;
  width: 24px;
  margin-right: 8px;
  margin-left: 5px;
}
@media (max-width: 586px) {
  .square img {
    height: 18px;
    width: 18px;
    margin-right: 6px;
    margin-left: 3px;
  }
  .square p {
    font-size: 13px;
    /* Optional: Adjust text size */
  }
  .detailsquare img {
    height: 18px !important;
    width: 18px !important;
 
  }
  
  .detailsquare p {
    font-size: 13px !important;
  }
}

.square p {
  color: #fff;
  margin: 0;
  font-size: 16px;
  /* Optional: Adjust text size */
}
@media screen and (max-width: 586px) {
  .square p {
    font-size: 13px;
    /* Optional: Adjust text size */
  }
  .selected-date{
    font-size: 16px  !important;
  }
  .selectedtimetext p {
    font-size: 16px !important;
  }
  .selectedGuestText p {
    font-size: 14px !important;
  }
  .AddDetailText p{
    font-size: 16px !important;
  }
}

.triangle-right {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 20px solid #F6832D;
  border-bottom: 20px solid transparent;
}

.detailsquare {
  height: 100%;
  width: 85px;
  background-color: #8080808C;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* Center align content vertically */
}

.detailsquare img {
  height: 24px;
  width: 24px;
  margin-right: 8px;
  margin-left: 5px;
}

.detailsquare p {
  color: #fff;
  margin: 0;
  font-size: 16px;
  /* Optional: Adjust text size */
}

.detailtriangle-right {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 20px solid #8080808C;
  border-bottom: 20px solid transparent;
}

.BookTableTime p {
  font-size: 18px;
  color: #000;
}
.input-container {
  position: relative;
}

.input-txt11111 {
  width: 289px;
  height: 40px;
  padding: 0px 10px 0px 10px;
  margin-top: 1rem;
  border-radius: 4px;
  border: 1.5px solid #737373;
  text-align: left;
  outline: none;
}

.input-txt11111::placeholder {
  font-size: 16px;
  font-weight: 600;
  color: #666;
}
.input-txt11111:focus {
  border-color: red; /* Change border to red on focus */
}
.floating-label {
  position: absolute;
  top: 5%;
  left: 10px;
  transform: translateY(0%);
  font-size: 14px;
  color: #999;
  background-color: #ffffff;
  opacity: 0; /* Initially hidden */
  pointer-events: none;
  transition: all 0.2s ease;
}
.input-txt11111:focus + .floating-label,
.input-txt11111:not(:placeholder-shown) + .floating-label {
  top: 6px;
  font-size: 12px;
  color: #374151;
  opacity: 1; /* Show the label */
}
.form_input1123 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
   /* padding: 10px 15px; */
  /* gap: 5px; */
}

.form_box1111 {
  margin-top: 1rem;
  background-color: #fff;
  border-radius: 6px;
 
}

.form-btn111 {
  margin: 1rem 0rem;
  width: 264px;
  height: 34px;
  border-radius: 3px;
  background-color: #F58634;
  border-color: transparent;
  color: #fff;
}

.form-btn111.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.contianerTimings1111 {
  overflow: auto;
  padding: 0.5rem 1rem;
  width: 100%;
}

.calendar_modal_heading4324 {
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0;
}

.time-selection-scrollable {
  max-height: 240px;
  /* Control the height of the scrollable area */
  overflow-y: auto;
  /* Enable vertical scrolling */
  padding: 16px;
}
.selected-date{
  font-size: 18px  ;
}

.selectedtimetext p {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  margin-top: 5px;
}

.discount {
  color: red;
  font-weight: bold;
  font-size: 16px;
}

.time_discount_container {
  display: grid !important;
  gap: 0.6rem !important;
  cursor: pointer;
  grid-template-columns: repeat(4, 1fr);
}

.time_discount_sectione332 {
  position: relative;
  margin-bottom: 1rem;
}

.time_section244 p {
  border: 0.2px solid #535969;
  border-radius: 5px;
  height: 36px;
  width: 58px;
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  color: #535969;
  display: flex;
  transition: background-color 0.3s, border-color 0.3s;
  align-items: flex-start;
  justify-content: center;
}

.time_discount_sectione332.available {
  background-color: #fff;
}

.time_discount_sectione332.unavailable {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.time_discount_sectione332:hover.available {
  border-color: #007bff;
  background-color: #f0f8ff;
}

.discount_section2333 p {
  border: 1px solid var(--secondary-black);
  padding: 0.1rem 0.7rem;
  background-color: #535969;
  z-index: 9;
  border-radius: 4px;
  font-size: 8px;
  width: 30px;
  height: 19px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 28px;
  right: auto;
  left: 14px;
}

.padding_no_show_msg333 {
  padding: 15px;
  background-color: #eee !important;
  color: #666666 !important;
}

.wrapper_calendar_modal11111111111 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 5px 32px;
  margin-bottom: 5px;
}

.resrvDateSelect ul {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  gap: 5px;
  margin: 0;
}

.resrvDateSelect ul li {
  flex: 20%;
  max-width: 60px;
}

.resrvDateSelect {
  position: relative;
}

.resrvDateSelect ul li p,
.addInputBtn {
  margin: 0;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  aspect-ratio: 1.8;
  border: 1px solid #535969;
  border-radius: 5px;
}

.selectedFormItems {
  background-color: #FFE9EB !important;
  border-radius: 5px;
  color: #535969 !important;
}

.selectedFormItems p {
  color: #4c4c51 !important;
  font-size: 18px !important;
}

.last-page-7666 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}.last-page-Boking {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.reservationText {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin: 0px;
}

.selectedGuestText {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  margin: 8px 1px 0px 0px;
  width: 100%;
}

.selectedGuestText p {
  font-size: 15px;
  font-weight: 600;
  margin: 0px;
}

.AddDetailText p{
  font-size: 18px;
  color:#A6A6A6;
  margin-top: 5px;
  font-weight: 600;
}

.book-table-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #313131;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
@media (max-width: 586px) {
  .book-table-button {
    left: auto;
    width: 80%;
    transform: translateX(13%);
    right: auto;
    margin: auto;
  }
}
.modal-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}
.modal-container.open {
  max-height: 500px; /* Adjust to the modal's height */
  opacity: 1;
}

.bottom_mark {
  margin: 0px 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: auto;
  position: absolute;
  bottom:10px;
  /* left: 0; */
  /* background-color: #585858; */
  width: 90%;
  margin-top: 1rem;
}

/* .bottom_mark {
  margin: 0px 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: auto;

  bottom: 5px;


  width: 100%;
  margin-top: 1rem;
} */

.bottom_mark1 {
  color: #fff;
  text-align: center;
}

.align_data {
  display: flex;
  height: 85%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.last_page_design {
  margin-top: 3rem;
  text-align: center;
}


.img_discount1 {
  text-align: center;
  width: 70%;
  height: 70%;
}

.image_design_bottom {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.image_design_bottom1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plus_minus_class {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.plus_btn {
  margin: 0;
  padding: 0rem 1rem;
  font-size: 20px;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  cursor: pointer;
  aspect-ratio: 1.8;
  border: 1px solid #535969;
  border-radius: 5px;
}

.text_box_data {
  margin: 0 1rem;
  color: #000;
  text-align: center;
}

.selectedFormItems.disabled {
  background-color: #ccc;
  /* Gray background */
  cursor: not-allowed;
  /* Disabled cursor */
  opacity: 0.6;
  /* Dim the element */
}

.per_discount_data {
  color: #2A2D32;
}

.set_discount_design {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}

/* .img_discount{

  width: 80%;
  height: 80%;
} */

.DiscountDiv {
  position: relative;
  width: 100%;
  /* Ensure the div takes the full width of the container */
}

.discountImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  /* Removes space below the image */
}

.DiscountContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(235, 217, 217, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2A2D32;
  text-align: center;
  padding: 20px;
  /* opacity: 0.5%; */
}

.discountPercentage {
  font-size: 2rem;
  font-weight: bold;
}

.timeRange {
  display: flex;
  justify-content: center;
  align-items: center;
}

.time {
  margin: 0 5px;
}

.to {
  margin: 0 5px;
  font-weight: bold;
}
.booking-details-container {
  width: 100%;
  max-width: 350px;
  /* margin: 20px auto; */
  padding: 10px;
  /* background-color: #f9f9f9; */
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.booking-detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
}

.booking-detail-row:last-child {
  border-bottom: none;
}

.detail-label {
  font-weight: 600;
  color: #fff !important;
  margin-right: 10px;
}

.detail-value {
  text-align: right;
  color: #fff;
}

.discount-tag {
  color: green;
  margin-left: 5px;
  font-size: 0.8em;
}
