/* Footer stylings */

.footer_head {
  background-image: url("../Assets/images/footer_bg.png");
  margin-top: 4rem;
}

.contact_head h4,
.contact_head p,
.contact_head li {
  color: #eb7c7c;
}

.contact_list li {
  padding: 0;
}

.contact_list li i {
  color: #fff;
}

.contact_list li .phone_pink,
.contact_list li .email_pink {
  width: 1.2rem;
}

.contact_list li .place_pink {
  width: 1rem;
}

footer .working table {
  width: 100%;
}

footer .working h4,
footer .working table td {
  color: #88f5f5;
  line-height: 2;
}

.footer_links h4,
.footer_links li a {
  color: #deaa3a !important;
  padding: 0;
}

li {
  list-style: none;
}

footer .row {
  padding: 3rem 0.5rem !important;
}

.column_1,
.column_2,
.column_3,
.column_4 {
  /* max-width: 450px; */
  height: fit-content;
  margin: 0 auto;
  font-family: "jmh_typewriterregular";
}

.column_3 {
  max-height: 350px;
}

ul {
  padding: 0;
}

.column_3 .footer_link_img img {
  max-width: 70%;
  position: absolute;
  right: 0;
}

.link_bg {
  max-width: 270px;
  height: inherit;
}

.footer_link_img .footer_links {
  /* position: absolute; */
  top: 0;
  left: 0;
  z-index: 10;
}

.social_icons_footer ul {
  padding: 0;
  display: flex;
}

.social_icons_footer ul img {
  margin-right: 1.5rem;
  width: 2.3rem;
}

.insta_img_wrapper img {
  width: 75%;
}

@media screen and (max-width: 991px) {
  .insta_img_wrapper img {
    width: 66%;
  }
}

@media screen and (max-width: 768px) {
  .footer_head {
    margin-top: 0;
  }

  .column_1,
  .column_2,
  .column_3 {
    max-width: 400px;
  }

  .insta_img_wrapper img {
    width: 100%;
  }

  .contact_head ul li {
    font-size: 15px;
    white-space: nowrap;
  }
}

.TeamFoottr button {
  color: #deaa3a;
  border: none;
  background-color: transparent !important;
}
