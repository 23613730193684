.App {
  display: flex;
  flex-direction: column;
}

* {
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "jmh_typewriterregular";
  src: url("../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff2")
      format("woff2"),
    url("../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "segoe_uibold";
  src: url("../Assets/fonts/Sergoi/segoe_ui_bold-webfont.woff2") format("woff2"),
    url("../Assets/fonts/Sergoi/segoe_ui_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "mv_boliregular";
  src: url("../Assets/fonts/muBoli/mvboli_1-webfont.woff2") format("woff2"),
    url("../Assets/fonts/muBoli/mvboli_1-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "intro_rust_gbase_2_line";
  src: url("../Assets/fonts/intro/intro_font-webfont.woff2") format("woff2"),
    url("../Assets/fonts/intro/intro_font-webfont.woff") format("woff");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "square721_cn_btroman";
  src: url("../Assets/fonts/square/sqr721c-webfont.woff2") format("woff2"),
    url("../Assets/fonts/square/sqr721c-webfont.woff") format("woff");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "kingthings_trypewriter_2Rg";
  src: url("../Assets/fonts/Trypewriter_2/kingthings_trypewriter_2-webfont.woff2")
      format("woff2"),
    url("../Assets/fonts/Trypewriter_2/kingthings_trypewriter_2-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Navbar styting */

.booking_header .navbar ul li a {
  font-family: "kingthings_trypewriter_2Rg";
  text-transform: uppercase;
  -webkit-text-stroke: thin;
}

header.booking_header {
  background-image: url("../Assets/images/header_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 850px;
  margin-bottom: 3rem;
}

.header_container {
  background-image: url("../Assets/images/booking_header_bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 50%;
}

.header_bg {
  background: rgba(0, 0, 0, 0.5) !important;
  height: 50%;
}

/* .logo {
  max-width: 8.5rem;
  margin: 0 !important;
}

.logo img {
  width: 100%;
  height: 100%;
  vertical-align: baseline !important;
  margin: 0 !important;
}

@media only screen and (max-width: 1093px) {
  .logo img {
    width: 115px !important;
  }
}

nav img {
  margin-right: 0.3rem;
}

.navbar {
  display: flex;
  justify-content: space-around;
  padding: 1.5rem;
  z-index: 10 ;
}

nav ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center !important;
  font-weight: 800;
  font-size: 1rem;
  letter-spacing: 1px;
}

.navbar-nav .phone {
  font-size: 15px;
}

.booking_page nav ul a {
  font-family: 'jmh_typewriterregular';
  color: white !important;
  text-transform: uppercase;
  font-weight: 800 !important;
}



nav ul p {
  margin-bottom: 0 !important;
  padding-bottom: 10px !important;
  font-size: 15px;
}

.navbar button {
  border: 2px solid black;
}

nav ul .nav-item {
  transition: all 0.2s ease;
}

.nav-link-1 {
  text-decoration: none;
  padding-bottom: 10px !important;
}

.nav-link-1 span, .nav-link-1 p{
  font-size: 15px;
}

.nav-link {
  padding: 10px 30px !important;
  transition: all 0.3s ease;
}

.white_hover:hover {
  background-image: url('../Assets//images/link_bg_white.svg') !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #000 !important;
}

.navbar-nav {
  filter: none;
}

@media screen and (max-width: 991px) {
  .small_logo {
    max-width: 6rem !important;
    margin: auto;
  }

  .small_logo img {
    width: 100%;
    height: 100%;
  }

  .navbar {
    padding: 1rem;
  }

  nav ul a {
    margin: 0.5rem 0;
  }

  .navbar button {
    float: right !important;
  }
}

@media screen and (max-width: 576px) {

  .navbar {
    padding: 0.5rem;
  }

  .small_logo {
    max-width: 5rem !important;
    margin: 0;
  }

  .navbar-nav {
    margin-top: 0.5rem;
    align-items: center !important;
  }
} */

/* Navbar styling End */

/* Booking Div Styling */

.booking_page_container {
  padding: 0;
  position: relative;
  height: 100%;
}

.booking_page_container .form_div {
  /* background-image: url('../Assets/images/booking_table_bg2.png'); */
  /* height: 635px; */
  border-radius: 16px;
  position: relative;
  top: 6%;
  left: 0;
  right: 0;
  display: flex;
  text-align: center;
  justify-content: space-around;
  background-color: #f9f9f9;
  box-shadow: rgba(0, 0, 0, 0.12) -0.0625rem 0.1875rem 0.375rem;
}

.suggetions {
  width: 100%;
}

.booking_page_container .form_div .suggetions textarea {
  margin: 0 auto !important;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  border: none;
  font-family: "segoe_uibold";
  font-size: 1.2rem;
  letter-spacing: -1px;
  width: 95%;
}

@media only screen and (max-width: 1250px) {
  .booking_page_container .form_div hr,
  .form_div ul {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .booking_page_container .form_div {
    position: absolute;
    top: 8%;
    left: 0%;
    right: 0;
  }
}

.booking_page_container .form_div hr {
  width: 2px;
  height: 400px;
  border-left: 2px solid black;
  margin: auto 0rem;
}

.form_div ul {
  padding: 2.5rem 0;
  margin: auto 0;
}

.form_div li {
  list-style: none;
  font-family: "Bebas Neue", cursive;
  color: #1c1c1c;
  font-weight: 400;
  font-size: 1rem;
}

.booking_page_container .form_div h1 {
  font-family: "intro_rust_gbase_2_line";
  color: #000;
}

.booking_page_container .form_div p {
  font-family: "intro_rust_gbase_2_line";
  /* color: #000; */
}

.booking_page_container .form_div input {
  height: 45px;
  border: none;
  border-radius: 6px;
  margin: 0 auto !important;
  /* font-family: "segoe_uibold"; */
  letter-spacing: -1px;
  box-shadow: 0px 03px 7px 0px #00000040;
  outline: none;
  padding-left: 2.2rem !important;
}

.more_guests_input input {
  height: 40px !important;
  width: 100%;
  padding: 0.3rem 1rem;
  font-size: 15px !important;
  box-shadow: 0px 0px 2px 0px #00000040 !important;
  font-weight: 400 !important;
}

.more_guests_input1 {
  position: relative;
}

.more_guests_input1::before {
  content: "";
  background-image: url("../Assets/images/person.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 0.9rem;
  height: 0.9rem;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 999999999;
}

@media only screen and (max-width: 770px) {
  .more_guests_input1::before {
    content: "";

    position: absolute;
    top: 21px;
    left: 12px;
    z-index: 999999999;
  }

  .more_guests_input2::before {
    content: "";
    position: absolute;

    top: 21px !important;
    left: 12px;
    z-index: 999999999;
  }
}

.more_guests_input2 {
  position: relative;
}

.more_guests_input2::before {
  content: "";
  background-image: url("../Assets/images/escalator_warning.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 0.9rem;
  height: 0.9rem;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 999999999;
}

.more_guests_input3 {
  position: relative;
}

.more_guests_input3::before {
  content: "";
  background-image: url("../Assets/images/pets.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 0.9rem;
  height: 0.9rem;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 999999999;
}

.endFormInput1::before {
  content: "";
  background-image: url("../Assets/images/person.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 0.9rem;
  height: 0.9rem;
  position: absolute;
  top: 13px;
  left: 1.2rem;
  z-index: 50;
}

.endFormInput2 input {
  position: relative;
}

.endFormInput2::before {
  content: "";
  /* background-image: url("../Assets/images/call.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  width: 0.9rem;
  height: 0.9rem;
  position: absolute;
  top: 1.5rem;
  left: 2.3rem;
  z-index: 1000;
}

.endFormInput1,
.endFormInput3 {
  padding-left: 8px;
  position: relative;
}

.endFormInput4 {
  padding-left: 22px;
  padding-right: 13px;

  position: relative;
  width: 100%;
}

.validationnnn {
  padding-left: 15px;
  padding-right: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.PhnVald .endFormInput2 {
  padding: 0px;
  margin: 0px;

  width: 100%;
}

.PhnVald {
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.endFormInput3::before {
  content: "";
  background-image: url("../Assets/images/mail.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 17px;
  height: 13px;
  position: absolute;
  top: 13px;
  left: 1.2rem;
  z-index: 50;
}

.endFormInput4::before {
  content: "";

  background-image: url("../Assets/images/msg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 1rem;
  height: 0.9rem;
  position: absolute;
  top: 1.1rem;
  left: 2.1rem;
  z-index: 50;
}

/* .booking_page_container .form_div input:focus,
textarea:focus  {
  transform: scale(1.1);
} */

.booking_page_container .form_div .col-md-5 {
  margin: 6px auto !important;
}

.booking_page_container .form_div .booking_form {
  padding: 2rem 0rem;
  width: 100%;
}

.booking_page_container .form_div .booking_form .btn {
  background-color: #f25f5f;
  color: #000;
  font-family: "segoe_uibold";
  font-size: 1.2rem;
}

@media only screen and (max-width: 826px) {
  .booking_page_container .form_div {
    position: absolute !important;
    top: 10% !important;
    left: 0% !important;
    right: 0% !important;
  }
}

@media only screen and (max-width: 768px) {
  .booking_page_container .form_div {
    height: 610px;
    position: absolute !;
    top: 9% !important;
    left: 0% !important;
    right: 0% !important;
  }

  .booking_page_container .form_div input {
    margin: 8px auto;
    margin-bottom: 0 !important;
    /* width: 80%; */
    padding: 1rem 2.5rem;
    height: 42px;
  }

  textarea {
    margin-top: 0.5rem;
  }

  .owl_slider_all p {
    width: 100%;
  }

  .space_under_input {
    display: none;
  }

  .Chckbxxx input {
    margin-top: 0 !important;
  }

  .endFormInput3::before {
    top: 20px !important;
  }
}

@media only screen and (max-width: 772px) {
  .booking_page_container .form_div {
    width: 373px;
    height: 675px;
    position: absolute !important;
    top: 8% !important;
    left: 0 !important;
    right: 0 !important;
  }

  .booking_page_container .form_div .suggetions textarea {
    width: 80%;
  }

  .booking_page_container .form_div input {
    padding: 1rem 2rem;
  }
}

@media only screen and (max-width: 390px) {
  .booking_page_container .form_div {
    width: 97vw;
    /* height: 660px; */
    position: re !important;
    top: 7% !important;
    left: 0 !important;
    right: 0 !important;
  }

  .endFormInput3::before {
    top: 20px !important;
  }
}

.slider_item {
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 1rem;
  color: #000;
  cursor: pointer;
  width: 100% !important;
}

.time_slider_div {
  margin: auto;
}

.time_slider_div .owl-prev i,
.time_slider_div .owl-next i {
  font-size: 25px;
}

.time_slider_div .owl-prev {
  left: -20px;
}

.time_slider_div .owl-next {
  right: -20px;
}

.bg-salmon {
  background-color: salmon;
  color: white;
}

.active {
  background-color: #666;
  color: white;
  padding: 0.3rem;
  border-radius: 1rem;
}

.item {
  transition: all 0.2s ease;
}

.item:hover {
  transform: scale(1.1);
}

.owl-nav {
  position: absolute;
  top: 13px;
  left: 0;
  right: 0;
}

.owl-prev {
  position: absolute;
}

.owl-prev i,
.owl-next i {
  font-size: 30px !important;
}

.owl-next {
  position: absolute;
}

.navbar-toggler {
  background: white;
}

.nowrap {
  white-space: nowrap;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  /* background-image: url('../Assets/images/loader.gif');
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 11; */
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.active_silder {
  background-color: #666;
  color: white;
  padding: 0.3rem;
  border-radius: 1rem;
}

.owl-prev {
  color: white !important;
}

.owl-next {
  color: white !important;
}

.discount_no_time {
  color: red;
  font-size: 18px;
  font-weight: 600;
}

/* .rec-item-wrapper {
  width: 110px !important;
}

.item.slider_item {
  width: 100%;
}


@media only screen and (max-width: 600px) {
  .rec-item-wrapper {
    width: 100px !important;
  }

  .item.slider_item {
    height: 75px;
  }
} */

.rec-pagination {
  display: none !important;
}

.rec-arrow-right:hover:enabled,
.rec-arrow-right:focus:enabled,
.rec-arrow-left:hover:enabled,
.rec-arrow-left:focus:enabled {
  color: rgb(255, 255, 255);
  background-color: transparent !important;
  box-shadow: none !important;
}

.rec-arrow-left,
.rec-arrow-right {
  background-color: transparent !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255) !important;
  min-width: 20px !important;
}

.rec-slider-container {
  margin: 0 !important;
}

/* Table Calendar CSS by Arsalan @10/08/2023 */

.calendarTableWrapper i {
  color: #fff;
  font-size: 14px;
}

.calendarTableWrapper .iconDivDisabled i {
  color: #000 !important;
}

.calendarTableWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.iconDiv {
  background-color: #f58634;
  width: 80px;
  padding: 0.8rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 45px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

/* .iconDiv {
  position: relative;
  background-color: #00665c;
  width: 120px;
  height: 60px;
  padding: 10px;
  cursor: pointer;
}

.iconDiv::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 10px solid #00665c;
  transform: translateY(-50%);
  z-index: 999;
} */

.iconDivDisabled {
  opacity: 0.8;
  background-color: #eef6f5 !important;
  cursor: not-allowed;
}

.calendarHeader {
  background-color: #fff;
  width: fit-content;
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  padding: 10px;
  border-radius: 12px;
  /* box-shadow: rgba(0, 0, 0, 0.12) -0.0625rem 0.1875rem 1.375rem; */
}

.timeHeader {
  /* background-color: #fff; */
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 10px;
  border-radius: 12px;
}

.timeWrapper {
  width: 100%;
  overflow: auto;
}

.timeWrapper h6 {
  text-align: left;
}

.timeSlot {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  max-height: 290px;
}

.booking_form_container {
  margin-top: 3rem;
}

@media only screen and (max-width: 991px) {
  .timeSlot {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .booking_form_container {
    margin-top: 0 !important;
  }
}

.timeSlot p {
  color: #212121;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  border: 1px solid;
  padding: 5px;
  width: 72px;
  border-radius: 6px;
  cursor: pointer;
  background-color: #ffffff;
}

.timeSlot p:hover {
  background-color: #eef6f5;
  color: #16758f;
}

.timeWrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.timeWrapper::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.timeWrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.userHeader {
  padding-right: 7px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  border-radius: 6px 0px 0px 6px !important;
}

.booking_form .userHeader input {
  width: 100%;
  height: 38px;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 0px 2px 0px #00000040;
  position: relative;
  padding-left: 2.4rem;
  color: #1c1c1c;
}

.react-international-phone-input {
  background-color: red;
  border-radius: 0px 6px 6px 0px !important;
  height: 2.9rem !important;
}

.react-international-phone-input-container {
  padding-right: 13px;
  width: 100%;
  height: 38px !important;
  margin-bottom: 0.4rem !important;
}

.react-international-phone-input {
  width: 100%;
  height: 38px !important;
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  height: 40px !important;
}

.Chckbxxx {
  text-align: center;
  margin-left: 5px;
  margin-top: 0px;
  margin-bottom: -25px;
  font-size: 14px;
}

.Chckbxxx label {
  margin-left: 8px;
}

.Chckbxxx input {
  box-shadow: none !important;
  width: auto !important;
  height: auto !important;
}

.Chckbxxx input[type="checkbox"] {
  accent-color: #509d30 !important;
}

textarea {
  width: 100%;
  height: 80px;

  padding: 10px;

  border-radius: 6px !important;
  box-shadow: 0px 0px 2px 0px #00000040;
  position: relative;
  padding-left: 2.4rem;
  border: none;
  outline: none;
  color: #1c1c1c;
}

.iconDiv p {
  color: #fff !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  margin: 0 !important;
  font-size: 14px !important;
  font-weight: 600;

  width: 80px;
  text-align: center;
}

.bookBtn {
  display: block;
  justify-content: center;
}

.bookBtn button {
  background-color: #212121 !important;
  color: #ffffff !important;
  border: 1px solid #212121 !important;
  width: 100px;
  font-size: 15px !important;
}

@media only screen and (max-width: 576px) {
  .calendarTableWrapper {
    width: 100%;
    display: flex;

    padding: 0px;
  }

  .calendarHeader {
    margin-right: 16px;
  }

  .timeWrapper {
    margin-right: 20px;
  }

  .iconDiv {
    width: 100%px;
  }

  .userHeader {
    display: flex;

    padding-right: 30px;
  }

  .Chckbxxx {
    font-size: 14px;
    padding: 0px;
  }

  .Chckbxxx label {
    padding-left: 2px;
  }
}

@media only screen and (max-width: 600px) {
  .iconDiv p {
    font-size: 12px !important;
  }

  .timeHeader {
    width: 95% !important;
  }

  .nameinpttt {
    margin-top: -1.7rem;
  }
}

@media only screen and (max-width: 780px) {
  .endFormInput1::before {
    content: "";

    position: absolute;
    top: 14px;
    left: 1.2rem;
    z-index: 20;
  }

  .react-international-phone-input-container
    .react-international-phone-country-selector-button {
    margin-top: 7px;
  }
  .endFormInput3::before {
    top: 15px !important;
  }

  .endFormInput4::before {
    content: "";
    background-image: url("../Assets/images/msg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 1rem;
    height: 0.9rem;
    position: absolute;
    top: 26px;
    left: 2.1rem;
    z-index: 1000;
  }
  .react-international-phone-country-selector {
    margin-top: -8.5px !important;
  }
  .Chckbxxx {
    margin-left: 14px;
    margin-right: 12px;

    font-size: 12px;
  }
}

.discount_no_time {
  text-align: center !important;
  width: 100%;
}

.form_div {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header_bg {
  height: 40%;
}

.logo img {
  width: 80% !important;
  height: 80% !important;
}

/* End Table Calendar CSS by Arsalan @10/08/2023 */

.max_guests_text {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  text-align: left;
  margin-bottom: 0;
  line-height: 20px;
  font-weight: 500;
}

.max_guests_error {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: 14px;
}

.more_guests {
  padding: 10px;
  padding-top: 0;
}

.more_guests_input {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.more_guests_input label {
  font-size: 14px;
}

.more_guests_input input {
  height: 40px !important;
  width: 100%;
  padding: 0.3rem 1rem !important;
  font-size: 15px !important;
  box-shadow: 0px 0px 2px 0px #00000040 !important;
  font-weight: 400 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.next_form_btn {
  display: flex;
  justify-content: center;
}

.next_form_btn button {
  width: 100px;
  padding: 0.2rem 1rem;
  border: none;
  background-color: #212121;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
}

.next_form_btn button:hover {
  background-color: #f58634;
}

.blog_show_image_show img {
  aspect-ratio: 2/1 !important;
  width: 100% !important;
}

.block_table {
  background-color: #eee !important;
  color: #666666 !important;
}

.block_table span {
  color: #666666 !important;
}

.block_table:hover {
  background-color: #eee !important;
  color: #666666 !important;
  cursor: not-allowed;
}

.react-international-phone-country-selector-button {
  height: 48px !important;
}

.react-international-phone-country-selector {
  margin-left: 23px;
  z-index: 99;
  margin-top: -0.75px;
}

.condition_error,
.invalid_data,
.errorMsg {
  color: #ff1212;
  font-size: 12px;
  font-weight: 400;
}

/*-------Model--------*/
.BookingMdl {
  display: flex;
  justify-content: center !important;
}

.BookingMdl .modal-dialog {
  max-width: 32rem !important;
  text-align: center;
}

.BookingMdl .modal-body {
  position: relative;
  height: 80px;
}

.BookingMdl .modal-body p {
  font-weight: 500;
}

.BookingMdl .modal-header {
  padding-bottom: 0 !important;
  border: none !important;
}

.suceflllIcon {
  display: contents;
}

.suceflllIcon .modal-title {
  height: 100%;
  width: 100%;
  display: flex !important;
  justify-content: center !important;
}

.suceflllIcon .modal-title img {
  height: 5rem;
  animation: zoom-in 1s forwards;
}

@keyframes zoom-in {
  0% {
    transform: scale(0);
    /* Start with normal scale */
  }

  50% {
    transform: scale(1.2);
    /* Zoom in to 120% scale */
  }

  100% {
    transform: scale(1);
    /* Zoom in to 120% scale */
  }
}

.modal-header .btn-close {
  width: 0px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeInModal {
  animation: fadeIn 0.5s ease-in-out;
}

.show_1px_red {
  border: 1px solid red !important;
}

.cancelBtn,
.ConfirmBtn {
  border: none;
}

.cancelBtn {
  background-color: #666666;
}

.cancelBtn:hover {
  background-color: #4d4d4d;
}

.ConfirmBtn {
  background-color: #f58634;
}

.ConfirmBtn:hover {
  background-color: #fc791b;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: transparent !important;
}

.kitchenModal .modal-dialog {
  width: auto !important;
}
/*--------Privacy Policy CSS---------*/

.leftMarginTbl {
  width: 11rem;
}
