* {
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'jmh_typewriterregular';
  src: url('../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff2') format('woff2'),
    url('../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'mv_boliregular';
  src: url('../Assets/fonts/muBoli/mvboli_1-webfont.woff2') format('woff2'),
    url('../Assets/fonts/muBoli/mvboli_1-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'intro_rust_gbase_2_line';
  src: url('../Assets/fonts/intro/intro_font-webfont.woff2') format('woff2'),
    url('../Assets/fonts/intro/intro_font-webfont.woff') format('woff');

  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'square721_cn_btroman';
  src: url('../Assets/fonts/square/sqr721c-webfont.woff2') format('woff2'),
    url('../Assets/fonts/square/sqr721c-webfont.woff') format('woff');

  font-weight: normal;
  font-style: normal;

}

/* Team section styling */

section.team_section {
  margin: 7rem 0;
}

.team_wrapper h2 {
  font-family: 'jmh_typewriterregular';
  letter-spacing: 2px;
  -webkit-text-stroke: 0.5px;
  text-align: center;
}

.team_member {
  margin: auto;
  margin-top: 6rem;
  text-align: center;
  font-family: 'jmh_typewriterregular';
  -webkit-text-stroke: 0.5px;
}

.team_member .member_img {
  margin: auto;
  border-radius: 50%;
  position: relative;
  z-index: -10;
}

.member_img img {
  width: 80%;
  border-radius: 50%;
  border: 6px solid white;
}

.member_detail {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: -30px;
}

.member_detail_1 {
  background-image: url('../Assets/images/pink\ layer.svg');
}

.member_detail_2 {
  background-image: url('../Assets/images/blue_layer.svg');

}

.member_detail_3 {
  background-image: url('../Assets/images/orange_layer.svg');

}

.team_member .detail_text h4,
.team_member .detail_text p {
  margin: 0;
}

.team_member .detail_text {
  padding: 1rem;
}

@media screen and (max-width: 1200px) {
  .team_member .detail_text h4 {
    font-size: 1.1rem;
  }

  .team_member .detail_text p {
    font-size: 0.9;
  }
}

@media screen and (max-width: 576px) {

  section.team_section {
    margin: 4rem 0;
  }

  .team_member {
    margin-top: 3rem;
  }
}

/* Careers CSS by Arsalan@19-04-2023*/

@font-face {
  font-family: 'jmh_typewriterregular';
  src: url('../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff2') format('woff2'),
    url('../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.careerHeadWrapper {
  /* margin-top: 0rem !important; */
  margin-bottom: 7rem;
}

.careerHeadWrapper h4 {
  font-size: 34px;
  font-family: 'jmh_typewriterregular';
  -webkit-text-stroke: thin;
}

.careerHeadWrapper p {
  color: #000;
  font-size: 20px;
  line-height: 2;
  font-family: 'jmh_typewriterregular';
}

.read_more_career {
  padding: 0.2rem 1.5rem;
  margin-top: 1rem;
  border: 1px solid #707070;
  border-radius: 8px;
  background-color: rgb(70, 86, 65, 0.9);
  color: #fff;
}

.read_more_text {
  color: #000;
  font-size: 20px;
  line-height: 2;
  font-family: 'jmh_typewriterregular';
}

.applyNowWrapper h1 {
  font-family: 'jmh_typewriterregular';
  -webkit-text-stroke: thin;
  text-transform: uppercase;
  font-size: 50px;
}

.applyInputHead input,
.applyInputHead select,
.applyInputHead button {
  margin: 1.5rem auto;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

.applyInputHead select,
.input_file,
.applyInputHead label {
  width: 100%;
}

.applyInputHead label {
  font-family: 'jmh_typewriterregular';
  font-size: 30px;
  text-align: left;
  padding-left: 1rem;
  margin-top: 1rem;
}

.apply_5 {
  font-size: 30px;
  font-family: 'jmh_typewriterregular';
  border-color: 1px solid #707070;
}

.apply_6 {
  font-family: 'jmh_typewriterregular';
  border-color: 1px solid #707070;
}

.apply_6[type=file]::file-selector-button {
  background-color: #D85D1D;
}

#file-upload-button {
  background-color: #EB7C7C;
}

.input_file {
  border: 1px solid;
  padding-top: 15px;
  background-color: #fff;
  width: 100%;
}

.applyInputHead button {
  width: 100%;
}

.apply_1 {
  background-color: #eb7c7ca5 !important;
  border-color: #707070;
}

.apply_2 {
  background-color: rgb(186, 186, 13) !important;
  border-color: #707070;
}

.apply_3 {
  background-color: #94c209a7 !important;
  border-color: #707070;
}

.apply_4 {
  background-color: #3ac1afa7 !important;
  border-color: #707070;
}

.apply_1,
.apply_2,
.apply_3,
.apply_4 {
  width: 100%;
  font-size: 30px;
  font-family: 'jmh_typewriterregular';
}

.input_margin {
  margin: auto;
}

.display_none {
  display: none;
}

.apply_1::placeholder,
.apply_2::placeholder,
.apply_3::placeholder,
.apply_4::placeholder {
  color: #000;
}

.resumeDiv {
  display: flex;
  justify-content: center;
}

.applyInputHead .btn {
  background-color: #465641;
  border-radius: 0;
  font-family: 'jmh_typewriterregular';
  font-size: 25px;
  border: 1px solid #707070;
}

@media screen and (max-width: 576px) {
  .careerHeadWrapper {
    margin-top: 4rem;
    margin-bottom: 5rem;
  }

  .careerHeadWrapper h4 {
    font-size: 30px;
  }

  .careerHeadWrapper p {
    margin: 0 !important;
  }

  .applyNowWrapper h1 {
    font-size: 45px;
  }

  .apply_1,
  .apply_2,
  .apply_3,
  .apply_4,
  .apply_5 {
    font-size: 25px;
  }

  .applyInputHead label {
    font-size: 23px;
  }
}

.close_button {
  width: fit-content !important;
  padding: 0.5rem !important;
  height: fit-content !important;
  float: right !important;
  margin: 0 !important;
  border: none !important;
}

.modal-header {
  text-align: right !important;
  display: flex !important;
  justify-content: flex-end !important;
  padding-bottom: 0 !important;
  border: none;
}

.modal-body {
  padding-top: 0 !important;
}

.modal_text {
  font-size: 1.1rem;
  font-family: 'jmh_typewriterregular';
  font-weight: 600;
  letter-spacing: 1px;
}

/* Careers CSS by Arsalan@19-04-2023*/

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  /* background-image: url('../Assets/images/loader.gif');
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 11; */
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.color_red_bold {
  color: red;
  font-weight: 700;
}

.blogPostcontainer p {
  text-align: justify;
}

.blogpostContent12 p,
li,
a,
span,
table,
tr,
td,
th {
  font-family: revert;
  font-size: revert;
  color: revert;
  line-height: revert;
}


.captch_select {
  user-select: none;
  /* Disable text selection */
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}