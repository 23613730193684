/* .App {
  display: flex;
  flex-direction: column;
} */

* {
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'jmh_typewriterregular';
  src: url('../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff2') format('woff2'),
    url('../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'mv_boliregular';
  src: url('../Assets/fonts/muBoli/mvboli_1-webfont.woff2') format('woff2'),
    url('../Assets/fonts/muBoli/mvboli_1-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'intro_rust_gbase_2_line';
  src: url('../Assets/fonts/intro/intro_font-webfont.woff2') format('woff2'),
    url('../Assets/fonts/intro/intro_font-webfont.woff') format('woff');

  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'square721_cn_btroman';
  src: url('../Assets/fonts/square/sqr721c-webfont.woff2') format('woff2'),
    url('../Assets/fonts/square/sqr721c-webfont.woff') format('woff');

  font-weight: normal;
  font-style: normal;

}

/* Navbar styting */

/* .logo {
  max-width: 9rem;
  margin: 0 !important;
}

.logo img {
  margin: 0 !important;
  width: 100%;
  height: 100%;
  vertical-align: baseline !important;

}

nav img {
  margin-right: 0.3rem;
}

@media screen and (max-width: 1153px) {
  .logo img {
    width: 115px;
  }

  nav ul {
    font-size: .9rem !important;
  }

  .nav-link-1 span,
  .nav-link-1 p {
    font-size: 14px !important;
  }
}

.navbar {
  display: flex;
  justify-content: space-around;
  padding: 1.5rem !important;
}

nav ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center !important;
  font-weight: 800;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

.navbar-nav .phone {
  font-size: 16px;
  text-align: center !important;
}

.team_header nav ul a {
  font-family: 'jmh_typewriterregular';
  color: black !important;
  text-transform: uppercase;
  font-weight: 800 !important;
}

nav ul p {
  margin-bottom: 0 !important;
  padding-bottom: 10px !important;
  font-size: 18px;
}

.navbar button {
  border: 2px solid black;
}

nav ul .nav-item {
  transition: all 0.2s ease;
}

.nav-link-1 {
  text-decoration: none;
  padding-bottom: 10px !important;
}

.nowrap {
  white-space: nowrap;
}

.nav-link-1 span,
.nav-link-1 p {
  font-size: 17px;
}

.nav-link {
  padding: 10px 30px !important;
  transition: all 0.3s ease;
}

.nav-link:hover {
  background-image: url('../Assets/images/link_bg.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #EBD967 !important;

}

.navbar-nav {
  filter: none;
}

@media screen and (max-width: 991px) {
  .small_logo {
    max-width: 6rem !important;
    margin: auto;
  }

  .small_logo img {
    width: 100%;
    height: 100%;
  }

  .navbar {
    padding: 1rem;
  }

  nav ul a {
    margin: 0.5rem 0;
  }

  .navbar button {
    float: right !important;
  }
}

@media screen and (max-width: 576px) {

  .navbar {
    padding: 0.5rem;
  }

  .small_logo {
    max-width: 5rem !important;
    margin: 0;
  }

  .navbar-nav {
    margin-top: 0.5rem;
    align-items: center !important;
  }
} */

/* Navbar styling End */

/* Team section styling */

section.team_section {
  margin-bottom: 7rem;
  margin-top: 7;
}

.team_wrapper h2 {
  font-family: 'jmh_typewriterregular';
  letter-spacing: 2px;
  -webkit-text-stroke: 0.5px;
  text-align: center;
}

.team_member {
  margin: auto;
  margin-top: 6rem;
  text-align: center;
  font-family: 'jmh_typewriterregular';
  -webkit-text-stroke: 0.5px;
}

.team_member .member_img {
  margin: auto;
  border-radius: 50%;
  position: relative;
  z-index: -10;
}

.member_img img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 6px solid white;
}

@media screen and (max-width: 991px) {
  .member_img img {
    width: 190px;
    height: 190px;
  }
}

@media screen and (max-width: 768px) {
  .member_img img {
    width: 240px;
    height: 240px;
  }
}

@media screen and (max-width: 400px) {
  .member_img img {
    width: 190px;
    height: 190px;
  }
}

.member_detail {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: -30px;
}

.member_detail_1 {
  background-image: url('../Assets/images/pink\ layer.svg');
}

.member_detail_2 {
  background-image: url('../Assets/images/blue_layer.svg');

}

.member_detail_3 {
  background-image: url('../Assets/images/orange_layer.svg');

}

.team_member .detail_text h4,
.team_member .detail_text p {
  margin: 0;
}

.team_member .detail_text {
  padding: 1rem;
}

@media screen and (max-width: 1200px) {
  .team_member .detail_text h4 {
    font-size: 1.1rem;
  }

  .team_member .detail_text p {
    font-size: 0.9;
  }
}

@media screen and (max-width: 576px) {

  .team_member {
    margin-top: 3rem;
  }
}

/* Footer stylings */

/* .footer_head {
  background-color: #101010;
}

.contact_head h4,
.contact_head p,
.contact_head li {
  color: #EB7C7C;
}

.contact_list li {
  padding: 0;
}

.contact_list li i {
  color: #fff;
}
 
.contact_list li .phone_pink,
.contact_list li .email_pink {
  width: 1.2rem;
}

.contact_list li .place_pink {
  width: 1rem;
}

footer .working table {
  width: 100%;
}

footer .working h4,
footer .working table td {
  color: #88F5F5;
  line-height: 2;
}

.footer_links h4,
.footer_links li {
  color: #DEAA3A;
  padding: 0;
}

li {
  list-style: none;
}

footer .row {
  padding: 3rem;
}

.column_1,
.column_2,
.column_3,
.column_4 {
  height: fit-content;
  margin: 0 auto;
  font-family: 'jmh_typewriterregular';
}

.column_3 {
  max-height: 350px;
}

ul {
  padding: 0;
}

.column_3 .footer_link_img img {
  max-width: 70%;
  position: absolute;
  right: 0;
}

.link_bg {
  max-width: 270px;
  height: inherit;
}

.footer_link_img .footer_links {
  top: 0;
  left: 0;
  z-index: 10;
}

.social_icons_footer ul {
  padding: 0;
  display: flex;
}

.social_icons_footer ul img {
  margin-right: 0.5rem;
  width: 2.2rem;
}

.insta_img_wrapper img {
  width: 66%;
}

@media screen and (max-width: 768px) {

  .column_1,
  .column_2,
  .column_3 {
    max-width: 400px;
  }

  .footer_link_img {
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .insta_img_wrapper img {
    width: 100%;
  }
} */


/* Careers CSS by Arsalan@19-04-2023*/

@font-face {
  font-family: 'jmh_typewriterregular';
  src: url('../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff2') format('woff2'),
    url('../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.careerHeadWrapper {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.careerHeadWrapper h4 {
  font-size: 34px;
  font-family: 'jmh_typewriterregular';
  -webkit-text-stroke: thin;
}

.careerHeadWrapper p {
  color: #000;
  font-size: 20px;
  line-height: 2;
  font-family: 'jmh_typewriterregular';
}

.applyNowWrapper h1 {
  font-family: 'jmh_typewriterregular';
  -webkit-text-stroke: thin;
  text-transform: uppercase;
  font-size: 50px;
}

.applyInputHead input,
.applyInputHead select,
.applyInputHead button {
  margin: 1.5rem auto;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

.applyInputHead select,
.input_file,
.applyInputHead label {
  width: 100%;
}

.applyInputHead label {
  font-family: 'jmh_typewriterregular';
  font-size: 30px;
  text-align: left;
  padding-left: 1rem;
  margin-top: 1rem;
}

.apply_5 {
  font-size: 30px;
  font-family: 'jmh_typewriterregular';
}

.apply_6 {
  font-family: 'jmh_typewriterregular';
}

#file-upload-button {
  background-color: #EB7C7C;
}

.input_file {
  border: 1px solid;
  padding-top: 15px;
  background-color: #fff;
  width: 100%;
}

.applyInputHead button {
  width: 100%;
}

.apply_1 {
  background-color: #EB7C7C;
}

.apply_2 {
  background-color: #FFC341;
}

.apply_3 {
  background-color: #93C209;
}

.apply_4 {
  background-color: #3AC1AE;
}

.apply_1,
.apply_2,
.apply_3,
.apply_4 {
  width: 100%;
  font-size: 30px;
  font-family: 'jmh_typewriterregular';
}

.input_margin {
  margin: auto;
}

.apply_1::placeholder,
.apply_2::placeholder,
.apply_3::placeholder,
.apply_4::placeholder {
  color: #000;
}

.resumeDiv {
  display: flex;
  justify-content: center;
}


@media screen and (max-width: 576px) {
  .careerHeadWrapper {
    margin-top: 4rem;
    margin-bottom: 5rem;
  }

  .careerHeadWrapper h4 {
    font-size: 30px;
  }

  .careerHeadWrapper p {
    margin: 0 !important;
  }

  .applyNowWrapper h1 {
    font-size: 45px;
  }

  .apply_1,
  .apply_2,
  .apply_3,
  .apply_4,
  .apply_5 {
    font-size: 25px;
  }

  .applyInputHead label {
    font-size: 23px;
  }
}

/* Careers CSS by Arsalan@19-04-2023*/