.table th {
  font-size: 14px;
  color: #696969;
  font-weight: 400;
}

.privacy_policy_container h6 {
  font-weight: 600;
  margin: 2rem 0 1rem 0;
  color: #2f2f2f !important;
}

.privacy_policy_container h1 {
  color: #2f2f2f !important;
  margin: 3rem 0 0 0;
}

.privacy_policy {
  font-size: 14px;
  color: #696969;
  font-weight: 400;
}

.privacy_policy_container p {
  font-size: 14px;
  color: #696969;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}

.privacy_policy_container a {
  color: #800080 !important;
  text-decoration: underline;
}

.privacy_policy_container {
  margin-bottom: 5rem !important;
}

.privacy_policy .privacy_policy_container ul {
  padding-left: 1.5rem !important;
}

.privacy_policy .privacy_policy_container ul li {
  list-style: disc !important;
}

.table td,
.table th {
  border: 1px solid lightgrey;
}