.App {
  display: flex;
  flex-direction: column;
}

* {
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "kingthings_trypewriter_2Rg";
  src: url("../Assets/fonts/Trypewriter_2/kingthings_trypewriter_2-webfont.woff2")
      format("woff2"),
    url("../Assets/fonts/Trypewriter_2/kingthings_trypewriter_2-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "kingthings_trypewriter_2Rg";
  src: url("../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff2")
      format("woff2"),
    url("../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "mv_boliregular";
  src: url("../Assets/fonts/muBoli/mvboli_1-webfont.woff2") format("woff2"),
    url("../Assets/fonts/muBoli/mvboli_1-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "intro_rust_gbase_2_line";
  src: url("../Assets/fonts/intro/intro_font-webfont.woff2") format("woff2"),
    url("../Assets/fonts/intro/intro_font-webfont.woff") format("woff");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "square721_cn_btroman";
  src: url("../Assets/fonts/square/sqr721c-webfont.woff2") format("woff2"),
    url("../Assets/fonts/square/sqr721c-webfont.woff") format("woff");

  font-weight: normal;
  font-style: normal;
}

/* Navbar styting */

.logo {
  max-width: 12rem;
  margin: 0 !important;
}

.logo img {
  margin: 0 !important;
  width: 100%;
  height: 100%;
  /* vertical-align: baseline !important; */
}

nav img {
  margin-right: 0.3rem;
}

@media screen and (max-width: 1153px) {
  .logo img {
    width: 115px;
  }

  nav ul {
    font-size: 0.9rem !important;
  }

  .nav-link-1 span,
  .nav-link-1 p {
    font-size: 14px !important;
  }
}

.navbar {
  display: flex;
  justify-content: space-around;
  padding: 1.5rem !important;
}

nav ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center !important;
  font-weight: 800;
  font-size: 1.2rem;
  letter-spacing: 2px;
  -webkit-text-stroke: 0.4px;
}

.navbar-nav .phone {
  font-size: 16px;
  text-align: center !important;
}

.home_page_header nav ul a {
  font-family: "kingthings_trypewriter_2Rg";
  color: #4e4a4a !important;
  text-transform: uppercase;
  font-weight: 800 !important;
}

nav ul p {
  margin-bottom: 0 !important;
  padding-bottom: 10px !important;
  font-size: 18px;
}

.navbar button {
  border: 2px solid black;
}

nav ul .nav-item {
  transition: all 0.2s ease;
}

.nav-link-1 {
  text-decoration: none;
  padding-bottom: 10px !important;
}

.nowrap {
  white-space: nowrap;
}

.nav-link-1 span,
.nav-link-1 p {
  font-size: 17px;
}

.nav-link {
  padding: 10px 30px !important;
  transition: all 0.3s ease;
}

.nav-link:hover {
  background-image: url("../Assets/images/link_bg.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* transform: scale(1.1); */
  color: #ebd967 !important;
}

.navbar-nav {
  filter: none;
}

@media screen and (max-width: 991px) {
  .small_logo {
    max-width: 6rem !important;
    margin: auto;
  }

  .small_logo img {
    width: 100%;
    height: 100%;
  }

  .navbar {
    padding: 1rem;
  }

  nav ul a {
    margin: 0.5rem 0;
  }

  .navbar button {
    float: right !important;
  }
}

@media screen and (max-width: 576px) {
  .navbar {
    padding: 0.5rem;
  }

  .small_logo {
    max-width: 5rem !important;
    margin: 0;
  }

  .navbar-nav {
    margin-top: 0.5rem;
    align-items: center !important;
  }
}

/* Navbar styling End */

/* Welcome banner Styling */

.welcome_banner {
  margin: 4rem 0 0 0;
}

.burger_bg {
  position: relative;
}

.burger_bg::before {
  content: " ";
  background-image: url("../Assets/images/burger_2.png");
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  /* animation-name: zoomInOut; */
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
  filter: none;
}

.ingredient_bg {
  background-image: url("../Assets/images/ingradient_2.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40%;
  height: 50%;
  position: absolute;
  top: -15%;
  right: 15%;
}

@keyframes zoomInOut {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

.welcome_bg p {
  font-size: 20px;
  color: #f03840;
  font-weight: 600;
  margin: 0;
}

.welcome_to_bg {
  background-image: url("../Assets/images/welcome_bg_pink.svg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 1.5rem;
  width: fit-content;
}

.food_factory_bg {
  background-image: url("../Assets/images/welocome_bg_yellow.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 1rem 3rem;
  width: fit-content;
  position: relative;
  z-index: -8;
}

.stigberets_img {
  width: 60% !important;
  height: 200% !important;
  z-index: -1 !important;
  position: absolute !important;
  bottom: -230px !important;
  left: -50px !important;
  opacity: 0.4;
}

.vega_logo {
  background-image: url("../Assets/images/vega_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: -50px;
  bottom: -270px;
  width: 43%;
  height: 132%;
  opacity: 0.4;
}

.welcome_bg {
  background-size: 90% 90%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.oo_img {
  background-image: url("../Assets/images/00_img.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  position: absolute;
  top: -15px;
  right: -70px;
  width: 40%;
  height: 40%;
  opacity: 0.5;

  /* animation-name: rotate; */
  animation-duration: 4s;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(-0deg);
  }
}

.welcome_bg h1 {
  letter-spacing: 1px;
  font-family: "intro_rust_gbase_2_line";
  text-align: left;
  margin: 0;
  font-size: 50px;
  color: #000;
  white-space: nowrap;
}

.barleys_underline div {
  text-decoration: underline solid black 3px;
}

.pink_bg {
  background-image: url("../Assets/images/welcome_bg_pink.svg");
  background-repeat: no-repeat;
  background-size: contain;
  letter-spacing: 1px;
  color: rgb(70, 86, 65);
  font-size: 45px;
  text-decoration: underline;
  margin: 0;
  position: absolute;
  top: 8px;
  left: 0;
  width: 70%;
  height: 46%;
  z-index: -1;
}

.heart_img {
  margin-left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  padding-bottom: 0.5rem;
}

.welcome_bg a h4 {
  font-size: 20px;
  padding: 30px;
  text-transform: uppercase;
  width: 150px;
  text-align: center;

  background-size: 100% 100%;
  color: #fff;
}

.welcome_bg a {
  text-decoration: none;
  color: #000;
}

.social-icons {
  position: fixed;
  right: 20px;
  bottom: 40%;
  height: 150px;
  width: 30px;
  z-index: 10;
  transition: all 0.3s ease;
}

.social-icons ul {
  margin: 0 !important;
  padding: 0 !important;
}

.social-icons li {
  list-style: none;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.social-icons a img {
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-out;
}

.social-icons a img:hover {
  transform: scale(1.2);
}

/* .fermen_logo {
  background-image: url('../Assets/images/fermen-removebg-preview.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  position: absolute;
  left: 18%;
  bottom: -125px;
  width: 14%;
  height: 71%;

  /* animation-name: rotate;
  animation-duration: 4s;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
} */

.good_guys_logo {
  background-image: url("../Assets/images/good_guys.png");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.8;
  position: absolute;
  right: 80px;
  bottom: -175px;
  width: 12%;
  height: 32%;
}

@media screen and (max-width: 1200px) {
  .welcome_bg h1 {
    font-size: 40px;
  }

  .barleys_underline span {
    font-size: 40px;
  }

  .burger_div {
    height: 350px;
  }
}

@media screen and (max-width: 991px) {
  .burger_div {
    height: 300px;
  }

  .welcome_bg h1 {
    font-size: 27px;
  }

  .barleys_underline span {
    font-size: 27px;
  }

  .oo_img {
    position: absolute;
    top: 10px;
    right: -50px;
  }

  .fermen_logo {
    position: absolute;
    left: 0px;
    bottom: -80px;
    width: 50%;
    height: 50%;
  }

  .good_guys_logo {
    position: absolute;
    right: 25px;
    bottom: -110px;
    width: 18%;
    height: 38%;
  }
}

@media screen and (max-width: 767px) {
  .burger_div {
    height: 300px;
  }

  .welcome_banner .row {
    display: flex;
    flex-direction: column-reverse;
  }

  .welcome_bg {
    margin: auto;
    margin-bottom: 5rem !important;
  }

  .oo_img {
    position: absolute;
    top: -100px;
    right: 75px;
    width: 25%;
    height: 100%;
  }

  .welcome_bg h1 {
    font-size: 40px;
  }

  .barleys_underline span {
    font-size: 40px;
  }

  .fermen_logo {
    position: absolute;
    left: 0px;
    bottom: -125px;
    width: 20%;
    height: 80%;
  }

  .good_guys_logo {
    position: absolute;
    right: 10px;
    bottom: -100px;
    width: 21%;
    height: 20%;
  }
}

@media screen and (max-width: 576px) {
  .welcome_banner {
    margin-top: 7rem;
  }

  .social-icons {
    position: fixed;
    right: 20px;
    bottom: 30%;
    width: 38px;
  }

  .fermen_logo {
    position: absolute;
    left: 50px;
    bottom: -125px;
    width: 20%;
    height: 80%;
  }

  .good_guys_logo {
    position: absolute;
    right: 15px;
    bottom: -80px;
    width: 21%;
    height: 20%;
  }

  .billdale_bg {
    position: absolute;
    top: 72%;
    right: 30%;
    width: 25%;
    height: 31%;
  }

  .spike_logo {
    position: absolute;
    bottom: -100px;
    left: 5% !important;
    width: 17% !important;
    height: 30% !important;
  }

  .brutal_logo {
    position: absolute;
    bottom: -100px;
    left: 25% !important;
    width: 9% !important;
    height: 22% !important;
  }

  .poppels_logo {
    position: absolute;
    bottom: -100px;
    left: 41% !important;
    width: 27%;
    height: 32% !important;
  }

  .stigbergets_logo {
    position: absolute;
    bottom: -140px !important;
    right: 25px !important;
    width: 24% !important;
    height: 44% !important;
  }

  .b_bg {
    position: absolute;
    top: 85% !important;
    right: 32px;
    width: 15%;
    height: 36% !important;
  }
}

@media screen and (max-width: 480px) {
  .welcome_bg h1 {
    font-size: 30px;
  }

  .barleys_underline span {
    font-size: 35px;
  }

  .oo_img {
    position: absolute;
    top: -80px;
    right: 35px;
  }

  .food_factory_bg {
    padding: 1rem 1.5rem;
  }
}

/* Welcome banner Styling End */

/* Content section styling */

.content_text {
  color: #4e4a4a;
  font-family: "kingthings_trypewriter_2Rg";
  font-size: 26px;
  letter-spacing: 2px;
  line-height: 2;
}

@media screen and (max-width: 991px) {
  .content_text {
    font-family: "kingthings_trypewriter_2Rg";
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 2;
  }
}

@media screen and (max-width: 576px) {
  .content_text {
    font-family: "kingthings_trypewriter_2Rg";
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 2;
  }
}

/* Welcome banner styling end */

/* Content section styling */

section.content_section {
  margin: 2rem 1rem;
}

.content_text_1 {
  color: #4e4a4a;
  margin-left: 2rem;
  margin-top: 5rem;
  padding: 1rem;
  font-family: "kingthings_trypewriter_2Rg";
  font-size: 22px;
  letter-spacing: 2px;
  line-height: 2;
  -webkit-text-stroke: 0.5px;
}

.akia_logo {
  background-image: url("../Assets/images/akia.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 25%;
  top: -70px;
  width: 15%;
  height: 86%;
  opacity: 0.6;
}

.poppels_2_logo {
  background-image: url("../Assets/images/poppels_2.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  position: absolute;
  bottom: -100px;
  right: 23%;
  width: 14%;
  height: 66%;
  opacity: 0.3;
}

.b_logo {
  background-image: url("../Assets/images/b_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 20%;
  right: 28px;
  width: 16%;
  height: 105%;
  opacity: 0.4;
}

.gotlands_logo {
  background-image: url("../Assets/images/gotlands_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -85%;
  right: 4%;
  width: 18%;
  height: 85%;
  opacity: 1;
}

.stockholm_logo {
  background-image: url("../Assets/images/stockholmbrewingco_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 37%;
  bottom: -130px;
  width: 22%;
  height: 43%;
  opacity: 0.4;
}

.billdale_bg {
  background-image: url("../Assets/images/billdale_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 70px;
  right: 22%;
  width: 20%;
  height: 60%;
  opacity: 0.8;
}

.spike_logo {
  background-image: url("../Assets/images/spike_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -170px;
  left: 18%;
  width: 15%;
  height: 60%;
  opacity: 0.8;
}

.brutal_logo {
  background-image: url("../Assets/images/brutal_breweing_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -180px;
  left: 38%;
  width: 7%;
  height: 41%;
  opacity: 0.8;
}

.poppels_logo {
  background-image: url("../Assets/images/popples_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -180px;
  left: 50%;
  width: 25%;
  height: 70%;
  opacity: 0.8;
}

.stigbergets_logo {
  background-image: url("../Assets/images/stigberget_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -260px;
  right: 50px;
  width: 21%;
  height: 92%;
  opacity: 0.8;
}

.fermen_logo {
  background-image: url("../Assets/images/fermen-removebg-preview.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  position: absolute;
  left: 6%;
  bottom: -220px;
  width: 18%;
  height: 90%;
  opacity: 0.4;
  /* animation-name: rotate; */
  animation-duration: 4s;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

@media screen and (max-width: 1200px) {
  .akia_logo {
    position: absolute;
    right: 25%;
    top: -30px;
    width: 15%;
    height: 50%;
    opacity: 0.6;
  }

  .poppels_2_logo {
    position: absolute;
    bottom: 24px;
    right: 23%;
    width: 14%;
    height: 36%;
    opacity: 0.3;
  }

  .b_logo {
    position: absolute;
    top: 24%;
    right: 28px;
    width: 16%;
    height: 42%;
    opacity: 0.4;
  }

  .gotlands_logo {
    position: absolute;
    bottom: -20%;
    right: 4%;
    width: 18%;
    height: 47%;
    opacity: 1;
  }

  .stockholm_logo {
    position: absolute;
    left: 37%;
    bottom: -130px;
    width: 22%;
    height: 25%;
    opacity: 0.4;
  }

  .fermen_logo {
    position: absolute;
    position: absolute;
    left: 18%;
    bottom: -160px;
    width: 18%;
    height: 50%;
    opacity: 0.4;
    /* animation-name: rotate; */
    animation-duration: 4s;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
}

@media screen and (max-width: 991px) {
  .stigberets_img {
    width: 60% !important;
    height: 200% !important;
    z-index: -1 !important;
    position: absolute !important;
    bottom: -170px !important;
    left: -50px !important;
    opacity: 0.4;
  }

  .vega_logo {
    position: absolute;
    right: -50px;
    bottom: -200px;
    width: 43%;
    height: 132%;
    opacity: 0.4;
  }

  .akia_logo {
    position: absolute;
    right: 24%;
    top: -30px;
    width: 15%;
    height: 41%;
    opacity: 1;
  }

  .poppels_2_logo {
    position: absolute;
    bottom: 116px;
    right: 23%;
    width: 14%;
    height: 29%;
    opacity: 0.3;
  }

  .fermen_logo {
    position: absolute;
    position: absolute;
    left: 10%;
    bottom: -160px;
    width: 18%;
    height: 39%;
    opacity: 0.4;
    /* animation-name: rotate; */
    animation-duration: 4s;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
}

@media screen and (max-width: 1075px) {
  .content_text_1 {
    margin-left: 1.5rem;
    margin-top: 5rem;
    padding: 0.8rem;
    font-family: "kingthings_trypewriter_2Rg";
    font-size: 20px;
    letter-spacing: 2px;
    -webkit-text-stroke: 1px;
    line-height: 2;
  }
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 832px) {
  .content_text_1 {
    margin-left: 1rem;
    margin-top: 5rem;
    padding: 0.8rem;
    font-family: "kingthings_trypewriter_2Rg";
    font-size: 18px;
    letter-spacing: 1.5px;
    -webkit-text-stroke: 1px;
    line-height: 2;
  }
}

@media screen and (max-width: 768px) {
  .content_text_1 {
    margin-left: 0rem;
  }
}

/* Content_1 section styling end*/

/* Content_2 section styling */

.content_div_2 {
  margin-top: 12rem;
}

.content_div_2_container {
  height: 800px;
}

.champagne_bg {
  position: relative;
}

/* .champagne_bg::before {
  content: " ";
  background-image: url("../Assets/images/champagne_bg.png");
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  animation-name: zoomInOut;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
  filter: none;
} */

.beer_img {
  vertical-align: baseline;
}

.content_text_2 {
  color: #4e4a4a;
  margin-top: 2rem;
  padding: 1rem;
  font-family: "kingthings_trypewriter_2Rg";
  font-size: 22px;
  letter-spacing: 2px;
  line-height: 1.8;
  -webkit-text-stroke: 0.5px;
}

.peanut_3 {
  position: absolute;
  top: -30px;
  position: absolute;
  right: 70px;
  width: 24%;
  height: 28%;
}

.smallPeanut2 {
  position: absolute;
  right: 163px;
  bottom: -48px;
  width: 17%;
  height: 23%;
}

.logos_div {
  height: inherit;
}

.beerbliotek {
  background-image: url("../Assets/images/beerbliotek_img.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 11%;
  left: 4%;
  width: 27%;
  height: 26%;
  opacity: 0.4;
  transform: rotate(10deg);
}

.joker {
  background-image: url("../Assets/images/joker_img.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 4%;
  left: 40%;
  width: 20%;
  height: 33%;
  opacity: 0.4;
}

.brutal {
  background-image: url("../Assets/images/brutal_logo_new.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -20px;
  right: 14%;
  width: 16%;
  height: 26%;
  opacity: 0.4;
}

.billdale {
  background-image: url("../Assets/images/billdale_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 24%;
  right: 12%;
  width: 24%;
  height: 25%;
  opacity: 0.5;
}

.good_guy {
  background-image: url("../Assets/images/good_guys.png");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.3;
  position: absolute;
  left: 35px;
  top: -174px;
  width: 22%;
  height: 33%;
  transform: rotate(-10deg);
}

.spike {
  background-image: url("../Assets/images/spike_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -170px;
  right: 0;
  width: 40%;
  height: 45%;
  opacity: 0.3;
}

.omni_logo {
  background-image: url("../Assets/images/omnipollo_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 22%;
  top: 30px;
  width: 16%;
  height: 38%;
  opacity: 0.8;
}

.pp_logo {
  background-image: url("../Assets/images/pp_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 17%;
  top: 140px;
  width: 25%;
  height: 35%;
  opacity: 0.8;
}

@media screen and (max-width: 991px) {
  .beer_div,
  .nonveg_div {
    display: flex;
    flex-direction: column;
  }

  .content_div_2_container {
    height: fit-content;
  }

  .logos_div {
    height: 335px;
  }
}

@media screen and (max-width: 768px) {
  .champagne_bg {
    height: 440px;
  }

  .omni_logo {
    position: absolute;
    left: 0%;
    top: 0px;
    width: 25%;
    height: 34%;
  }

  .stockholm_logo {
    position: absolute;
    left: 24%;
    top: 65px;
    width: 30%;
    height: 27%;
  }

  .pp_logo {
    position: absolute;
    right: 10%;
    top: 130px;
    width: 34%;
    height: 24%;
  }

  .vega_logo {
    position: absolute;
    right: -4%;
    top: -20px;
    width: 34%;
    height: 37%;
  }

  .ten_hands_logo {
    position: absolute;
    bottom: -100px;
    right: 0px;
    width: 33%;
    height: 37%;
  }
}

@media screen and (max-width: 576px) {
  .ten_hands_logo {
    position: absolute;
    bottom: -75px;
    right: 0px;
    width: 36%;
    height: 16%;
  }

  .omni_logo {
    position: absolute;
    left: 0%;
    top: 0px;
    width: 27%;
    height: 21%;
  }

  .stockholm_logo {
    position: absolute;
    left: 24%;
    top: 57px;
    width: 34%;
    height: 16%;
  }

  .pp_logo {
    position: absolute;
    right: 4%;
    top: 110px;
    width: 42%;
    height: 24%;
  }

  .content_text_1,
  .content_text_2,
  .content_text_3 {
    padding: 0;
    font-size: 18px !important;
  }
}

/* Content_2 section styling end */

/* Content_3 section styling */

.nonveg_bg .nonveg_img {
  width: 100%;
  height: 100%;
}

.nonveg_bg .red_chilli {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 46%;
  height: 41%;
  z-index: -1;
}

.chilli_powder {
  position: absolute;
  left: 0;
  top: 65px;
  width: 53%;
  height: 43%;
}

.tomato {
  position: absolute;
  right: -80px;
  top: 60px;
  width: 20%;
  height: 16%;
}

.coriender {
  position: absolute;
  left: 20%;
  top: 0;
  width: 32%;
  height: 22%;
}

.content_text_3 {
  color: #4e4a4a;
  margin: auto;
  padding: 1rem;
  font-family: "kingthings_trypewriter_2Rg";
  font-size: 22px;
  letter-spacing: 2px;
  line-height: 1.8;
  -webkit-text-stroke: 0.5px;
}

.brutal_logo_1 {
  background-image: url("../Assets/images/brutal_breweing_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -50%;
  left: 10%;
  width: 25%;
  height: 41%;
  opacity: 0.8;
}

@media screen and (max-width: 991px) {
  .gotlands_logo {
    position: absolute;
    bottom: -54%;
    right: 6%;
    width: 28%;
    height: 85%;
  }

  .content_div_3 {
    margin-top: -6rem;
  }

  .content_text_3 p {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .brutal_logo_1 {
    bottom: -72%;
    left: 10%;
    width: 25%;
    height: 50%;
  }

  .gotlands_logo {
    position: absolute;
    bottom: -75%;
    right: 10%;
    width: 36%;
    height: 85%;
  }
}

/* Content_3 section styling end */

/* Content_4 section styling */

.content_div_4 {
  margin-top: 4rem;
}

.content_text_4 {
  color: #4e4a4a;
  margin: auto;
  padding: 1rem;
  font-family: "kingthings_trypewriter_2Rg";
  font-size: 50px;
  letter-spacing: 2px;
  line-height: 1.8;
  -webkit-text-stroke: 0.5px;
}

.fries_img {
  width: 55%;
  /* height: 100%; */
}

.pp {
  background-image: url("../Assets/images/pp_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 5%;
  bottom: -110px;
  width: 50%;
  height: 33%;
  opacity: 0.8;
}

.old_island {
  background-image: url("../Assets/images/old_island_img.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -58%;
  right: 0%;
  width: 37%;
  height: 39%;
  opacity: 0.4;
}

.beerbliotek_logo {
  background-image: url("../Assets/images/beerbliotek_img.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0%;
  right: -40%;
  width: 57%;
  height: 33%;
  opacity: 0.8;
}

.dugges {
  background-image: url("../Assets/images/dugges.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 32%;
  width: 14%;
  height: 40%;
}

.ten_hands {
  background-image: url("../Assets/images/ten_hands_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0%;
  left: 18%;
  width: 25%;
  height: 41%;
  opacity: 0.4;
}

@media screen and (max-width: 991px) {
  .old_island_logo {
    position: absolute;
    top: 0%;
    right: -50%;
    width: 40%;
    height: 30%;
  }
}

@media screen and (max-width: 768px) {
  .old_island_logo {
    position: absolute;
    top: 70%;
    left: 3%;
    width: 36%;
    height: 27%;
  }

  .beerbliotek_logo {
    position: absolute;
    bottom: -10%;
    right: 0%;
    width: 47%;
    height: 33%;
  }
}

@media screen and (max-width: 576px) {
  .content_text_4 {
    color: #4e4a4a;
    margin: auto;
    padding: 1rem;
    font-family: "kingthings_trypewriter_2Rg";
    font-size: 25px;
    letter-spacing: 2px;
    line-height: 1.8;
    -webkit-text-stroke: 0.5px;
  }

  .ten_hands {
    position: absolute;
    bottom: 0%;
    left: 10%;
    width: 25%;
    height: 14%;
    opacity: 0.4;
  }

  .tomato,
  .red_chilli,
  .smallPeanut2 {
    display: none;
  }
}

.row {
  margin: 0 !important;
}
