.calendar-container {
  position: relative;
}

.date-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  background: #fff;
}


.selected-date {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  margin-top: 5px;
}

.calendar-modal {
  /* position: absolute;
  top: 45px;
  left: 0px; */
  /* width: 100%; */

  /* border: 1px solid #ddd;
  border-radius: 8px; */

  width:100%;
  margin: 8px 0px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-height: 350px;
}
.calendar-header {
  text-align: left;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
}

.calendar-header h3 {
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
}

.calendar-header p {
  font-size: 12px;
  font-weight: 600;
  color: #666;
  margin: 0px;
}

.booking-banner {
  margin-top: 8px;
  background-color: #D2E8F4;
  color: #535969;
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookingcont {
  display: flex;
  width: 100%;
  justify-content: center;
}

/* Adjust styles for the calendar header */
.MuiPickersCalendarHeader-root {
  font-family: "Roboto", sans-serif;
}

/* Styling for buttons (< and >) in the month navigation row */
.MuiPickersArrowSwitcher-button {
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

/* Styling for the selected date */
.Mui-selected {
  background-color: #374151 !important;
  /* Matches shared design */
  color: #ffffff !important;
}

/* Today's date */
.MuiPickersDay-today {
  border: 1px solid #374151 !important;
  color: #374151 !important;
}

/* Calendar days font and size */
.MuiPickersDay-root {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 586px) {
  .calendar-modal {
    width: 100%; /* Reduce width */
    margin: 5px 0px; /* Center align */
    border-radius: 5px; /* Smaller radius */
    max-height: 350px; /* Reduce max height */
  }

  .calendar-header h3 {
    font-size: 14px; /* Smaller title font size */
    font-weight: 600; /* Adjust weight */
  }

  .calendar-header p {
    font-size: 10px; /* Smaller subtitle font size */
    font-weight: 500; /* Adjust weight */
  }

  .booking-banner {
    margin-top: 6px;
    background-color: #d2e8f4;
    color: #535969;
    border-radius: 12px;
    padding: 3px 10px; /* Smaller padding */
    font-size: 10px; /* Smaller font size */
  }

  .MuiPickersDay-root {
    font-size: 10px; /* Smaller day font size */
    font-weight: 400; /* Adjust weight */
    color: #333;
  }

  .MuiPickersArrowSwitcher-button {
    font-size: 16px; /* Smaller arrow button size */
  }

  .Mui-selected {
    background-color: #535969 !important;
    color: #ffffff !important;
  }

  .MuiPickersDay-today {
    border: 1px solid #374151 !important;
    color: #374151 !important;
  }
}

/* time modal */

.time-modal {
  width:95%;
  margin: 15px 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.BookingTimeText p {
  font-size: 13px;
  color: #535969;
  margin: 0px;
  text-align: center;
  padding:8px;
}